import React from 'react';
import IntlMessages from "../../../util/IntlMessages";
import {NavLink} from "react-router-dom";

class MenuItem extends React.Component {
    render() {
        return (
            <li className={`MenuItem`}>
                <NavLink to={this.props.to} onClick={this.props.onClick} activeClassName="active">
                    <IntlMessages id={this.props.titleKey} />
                </NavLink>
            </li>
            )
    }
}

export default MenuItem;