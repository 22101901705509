import React from 'react';
import RightImage from "../../components/elements/RightImage";
import TurntableRightImage from "../../assets/images/right/right-turntable.jpg";
import Logo from "../../components/elements/Logo";
import SocialMenu from "../../components/elements/SocialMenu";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import LoginAuthentication from "./LoginAuthentication";
import {Route, Switch} from "react-router";
import LoginOverview from "./LoginOverview";
import LoginOptions from "./LoginOptions";
import LoginBuses from "./LoginBuses";
import PodcastLink from "../../components/elements/PodcastLink";

class Login extends React.Component {
    render() {
        return (
            <div className="Login">
                <RightImage source={TurntableRightImage}/>
                <div className="content-container">
                    <div className="content">
                        <Logo/>
                        <SocialMenu/>
                        <Footer/>
                        <Menu/>
                        <PodcastLink/>
                    </div>
                </div>
                <div className="left-text-column">
                    <Switch>
                        <Route path="/login/overview" render={() => (<LoginOverview/>)}/>
                        <Route path="/login/options" render={() => (<LoginOptions/>)}/>
                        <Route path="/login/buses" render={() => (<LoginBuses/>)}/>
                        <Route path="/login" component={() => (<LoginAuthentication/>)}/>
                    </Switch>
                </div>
            </div>
        )
    }
}

export default Login;