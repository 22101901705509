import React from 'react';
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import TextBlockWithLine from "../../components/elements/content/TextBlockWithLine";
import Button from "../../components/elements/Button";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import SupporterApi from "../../apis/Supporter";
import connect from "react-redux/src/connect/connect";
import {hideLoader, supporterSetAuthentication, showLoader} from "../../actions";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router";
import SupporterImage1 from "../../assets/images/supporter/supporter-1.jpg";
import ImageWithLine from "../../components/elements/content/ImageWithLine";

class SupporterIntro extends React.Component {

    state = {
        email: '',
        password: '',
        error: false
    };

    authenticate = (e) => {
        e.preventDefault();
        this.setState({error: false});
        this.props.showLoader();
        SupporterApi.authenticate(this.state.email, this.state.password)
            .then((response) => {
                if(response.status === 200 && response.data.roles.includes('ROLE_SUPPORTER')) {
                    this.props.supporterSetAuthentication(response.data);
                    this.props.history.push('/supporter/account');
                } else {
                    this.props.hideLoader();
                    this.setState({
                        error: true
                    });
                }
            })
            .catch((error) => {
                this.props.hideLoader();
                this.setState({
                    error: true
                });
            })
    };

    render() {
        const {intl} = this.props;
        return (
            <div className="left-text-column SupporterIntro">
                <HeadingWithLine lineColor="pink" textKey="supporter.intro.title"/>
                <ImageWithLine lineColor="white" imageSource={SupporterImage1}/>
                <div className="supporter-intro-login-form">
                    <div className="line-left line-color-pink"/>
                    {this.state.error ? (
                        <div className="error">
                            <IntlHtmlMessages id="supporter.intro.authentication.error"/>
                        </div>
                    ) : null}
                    <form onSubmit={this.authenticate}>
                        <div className="form-group">
                            <input className="input-field" type="text" value={this.state.email}
                                   onChange={(e) => this.setState({email: e.target.value})}
                                   placeholder={intl.formatMessage({id: "supporter.intro.authentication.email.label"})}/>
                        </div>
                        <div className="form-group">
                            <input className="input-field" type="password" value={this.state.password}
                                   onChange={(e) => this.setState({password: e.target.value})}
                                   placeholder={intl.formatMessage({id: "supporter.intro.authentication.password.label"})}/>
                        </div>
                        <Button onClick={this.authenticate} titleKey={"supporter.intro.authentication.submit"}/>
                    </form>
                </div>
                <TextBlockWithLine lineColor="pink" textKey="supporter.intro.text"/>
                <div className="supporter-info-register-button-container">
                    <Button isLink={true} to="/supporter/register" titleKey="supporter.intro.registerNow"/>
                </div>
            </div>
        )
    }
}

export default connect(null, {
    showLoader, hideLoader, supporterSetAuthentication
})(injectIntl(withRouter(SupporterIntro)));
