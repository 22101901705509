import React from 'react';
import IntlMessages from "../../util/IntlMessages";
import {Link} from "react-router-dom";
import animateScrollTo from 'animated-scroll-to';

class InfoTopRightMenu extends React.Component {
    render() {
        return (
            <div className="InfoTopRightMenu">
                <ul>
                    <li className={this.props.active === 'philosophy' ? 'active' : ''}>
                        <button onClick={() => animateScrollTo(document.querySelector('.info-philosophy'))}>
                            <IntlMessages id="info.menu.philosophy" />
                        </button>
                    </li>
                    <li className={this.props.active === 'location' ? 'active' : ''}>
                        <button onClick={() => animateScrollTo(document.querySelector('.info-location'))}>
                            <IntlMessages id="info.menu.location" />
                        </button>
                    </li>
                    <li className={this.props.active === 'awareness' ? 'active' : ''}>
                        <button onClick={() => animateScrollTo(document.querySelector('.info-awareness'))}>
                            <IntlMessages id="info.menu.awareness" />
                        </button>
                    </li>
                    <li className={this.props.active === 'contact' ? 'active' : ''}>
                        <button onClick={() => animateScrollTo(document.querySelector('.info-contact'))}>
                            <IntlMessages id="info.menu.contact" />
                        </button>
                    </li>
                </ul>
            </div>
        )
    }
}

export default InfoTopRightMenu;