import React from 'react';
import PodcastIcon from "../../assets/images/social/podcast.svg";
import {Link} from "react-router-dom";

class PodcastLink extends React.Component {
    render() {
        return (
            <Link to="/podcast" className="PodcastLink" onClick={this.props.onClick}>
                <img src={PodcastIcon} alt="Podcast"/>
            </Link>
        )
    }
}

export default PodcastLink;