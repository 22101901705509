import {
    GLOBAL_CLEAR_STATE,
    LOADER_HIDE,
    LOADER_SHOW, LOADER_SHOW_ERROR
} from "../config/actions";

const INIT_STATE = {
    visible: false,
    showError: false
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case LOADER_SHOW: {
            return {
                ...state,
                visible: true,
                showError: false
            }
        }
        case LOADER_HIDE: {
            return {
                ...state,
                visible: false,
                showError: false
            }
        }
        case LOADER_SHOW_ERROR: {
            return {
                ...state,
                showError: true
            }
        }
        case GLOBAL_CLEAR_STATE: {
            return INIT_STATE;
        }
        default:
            return state;
    }
}