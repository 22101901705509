import React from 'react';
import IntlMessages from '../../../util/IntlMessages';
import FooterItem from "./FooterItem";
import connect from "react-redux/src/connect/connect";
import {toggleLanguage} from "../../../actions";

class Footer extends React.Component {
    render() {
        return (
            <div className="Footer">
                <ul id="footer">
                    <a href="" className="otherLanguage" onClick={(e) => {e.preventDefault(); this.props.toggleLanguage();}}>
                        <IntlMessages id="footer.otherLanguage"/>
                    </a>
                    <div className="footer-menu">
                        <FooterItem to="/legals/imprint" titleKey="footer.imprint">
                            <IntlMessages id="footer.imprint"/>
                        </FooterItem>
                        <FooterItem to="/legals/agb" titleKey="footer.agb">
                            <IntlMessages id="footer.agb"/>
                        </FooterItem>
                        <FooterItem to="/legals/privacy" titleKey="footer.privacy">
                            <IntlMessages id="footer.privacy"/>
                        </FooterItem>
                    </div>
                </ul>
            </div>
        )
    }
}

export default connect(null, {toggleLanguage})(Footer);