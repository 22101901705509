import React from 'react';
import IntlMessages from "../../util/IntlMessages";
import NewsletterApi from "../../apis/Newsletter";
import RightImage from "../../components/elements/RightImage";
import MicRightImage from "../../assets/images/right/right-mic.jpg";
import Logo from "../../components/elements/Logo";
import SocialMenu from "../../components/elements/SocialMenu";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";

class NewsletterConfirm extends React.Component {
    state = {
        isLoading: true,
        success: false
    };

    componentDidMount() {
        NewsletterApi.confirmEntry(this.props.match.params.token)
            .then((response) => {
                this.setState({
                    isLoading: false,
                    success: response.data.data.success
                })
            });
    }

    render() {
        return (
            <div className="NewsletterConfirm">
                <RightImage source={MicRightImage}/>
                <div className="content-container">
                    <div className="content">
                        <Logo/>
                        <SocialMenu/>
                        <Footer/>
                        <Menu/>
                    </div>
                </div>
                <div className="left-text-column">
                    <div>
                        <HeadingWithLine lineColor="pink" textKey="newsletter.confirm.title"/>
                        <div className="info-text">
                            <div className={`line-left line-color-pink`}/>
                            {this.state.isLoading ? (
                                <div className="loading">
                                    Loading...
                                </div>
                            ) : (
                                this.state.success ? (
                                    <div className="success">
                                        <IntlMessages id="newsletter.confirm.success"/>
                                    </div>
                                ) : (
                                    <div className="error">
                                        <IntlMessages id="newsletter.confirm.error"/>
                                    </div>
                                )

                            )}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default NewsletterConfirm;