import React from 'react';
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import connect from "react-redux/src/connect/connect";
import {backToStep1, backToStep2} from "../../actions";

class TicketingStepProgress extends React.Component {
    render() {
        return (
            <div className="TicketingStepProgress">
                <span className={`step step-1${this.props.activeStep === 1 ? ' active' : ''}`} onClick={(this.props.activeStep === 2 || this.props.activeStep === 3) ? this.props.backToStep1 : null}>
                    <IntlHtmlMessages id="ticketing.stepProgress.step1"/>
                </span>
                <span className={`step step-2${this.props.activeStep === 2 ? ' active' : ''}`} onClick={this.props.activeStep === 3 ? this.props.backToStep2 : null}>
                    <IntlHtmlMessages id="ticketing.stepProgress.step2"/>
                </span>
                <span className={`step step-3${this.props.activeStep === 3 ? ' active' : ''}`}>
                    <IntlHtmlMessages id="ticketing.stepProgress.step3"/>
                </span>
            </div>
        )
    }
}
const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {
    backToStep1, backToStep2
})(TicketingStepProgress);