import React, {Fragment} from 'react';
import connect from "react-redux/src/connect/connect";
import {injectIntl} from "react-intl";
import LoginApi from '../../apis/Login';
import {clearState, hideLoader, loadAvailableProducts, logout, showLoader} from "../../actions";
import {withRouter} from "react-router";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import Button from "../../components/elements/Button";
import IntlMessages from "../../util/IntlMessages";
import LoginMenu from "./components/LoginMenu";
import {enableBodyScroll} from "body-scroll-lock/lib/bodyScrollLock.es6";
import ProductOptionInfo from "../Ticketing/ProductOptionInfo";
import PaypalButton from "../Ticketing/PaypalButton";
import InfoIcon from "../../assets/images/ticketing/Info.svg";
import InfoIconHover from "../../assets/images/ticketing/Info_Hover.svg";

class LoginOptions extends React.Component {

    state = {
        order: null,
        subOrderItems: [],
        agb: false,
        privacy: false,
        paymentType: '',
        errors: {},
        serverResponse: null
    };

    componentWillMount() {
        this.props.showLoader();
        this.props.loadAvailableProducts();
        LoginApi.loadOverview(this.props.login.bearerToken)
            .then((response) => {
                this.props.hideLoader();
                if (response.status === 200 && response.data.success) {
                    this.setState({order: response.data.data});
                } else {
                    this.props.history.push('/login');
                }
            })
            .catch((error) => {
                this.props.hideLoader();
                this.props.history.push('/login');
            })
    }

    handleToggleProductOption = (orderItemKey, productOptionId) => {
        let subOrderItems = this.state.subOrderItems;
        let existingSubOrderItem = subOrderItems.find((el) => el.key === orderItemKey);
        if (existingSubOrderItem) {
            subOrderItems.map((soi) => {
                if (soi.key === orderItemKey) {
                    if (soi.productOptionId !== productOptionId) {
                        soi.productOptionId = productOptionId;
                    } else {
                        soi.productOptionId = null;
                    }
                }
            });
        } else {
            subOrderItems.push({key: orderItemKey, productOptionId: productOptionId});
        }
        subOrderItems = subOrderItems.filter((el) => el.productOptionId);
        this.setState({
            subOrderItems: subOrderItems
        });
    };

    handleSave = () => {
        let payload = {
            order: this.state.order.orderNumber,
            paymentType: this.state.paymentType,
            agb: this.state.agb,
            privacy: this.state.privacy,
            subOrderItems: this.state.subOrderItems.map((soi, soiInde) => {
                return {
                    orderItem: soi.key,
                    productOptions: [
                        {
                            productOption: soi.productOptionId
                        }
                    ]
                }
            })
        };
        this.props.showLoader();
        LoginApi.createSubOrder(this.props.login.bearerToken, payload)
            .then((response) => {
                this.props.hideLoader();
                if(!response.data.success) {
                    this.setState({
                        errors: response.data.errors
                    });
                } else {
                    this.setState({
                        serverResponse: response.data.data
                    });
                }
            });
    };

    handlePayment = (data, actions) => {
        this.props.showLoader();
        LoginApi.executePaypalPayment(this.props.login.bearerToken, {
            subOrder: this.state.serverResponse.subOrderNumber,
            paymentID: data.paymentID,
            payerID: data.payerID
        })
            .then((response) => {
                this.props.hideLoader();
                if(response.data.success && !response.data.data.paymentError) {
                    this.props.history.push('/login/overview', {subOrderSuccess: true});
                } else {
                    alert('An error occurred. Please try again.');
                }
            })
    };

    toggleProductOptionInfo = (mode) => {
        this.setState({
            showProductOptionInfo: !this.state.showProductOptionInfo,
            showProductOptionInfoMode: mode
        });
        if(this.state.showProductOptionInfo) {
            enableBodyScroll(this.targetElement);
        } else {
            //this.targetElement = document.getElementById('ProductOptionInfoText');
            //disableBodyScroll(this.targetElement);
        }
    };

    doLogout = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
        this.props.logout();
    };

    render() {
        const {order, subOrderItems} = this.state;

        let oldTotalAmount = 0;
        let totalProductOptionsAmount = subOrderItems ? subOrderItems.reduce((current, subOrderItem) => {
            let orderItem = order.orderItems.find((el) => el.code === subOrderItem.key);
            if(orderItem) {
                let product = this.props.ticketing.availableProducts.find((el) => el.key === orderItem.product.key);
                if(product) {
                    let productOption = product.productOptions.find((el) => el.id === subOrderItem.productOptionId);
                    if(productOption) {
                        return current + parseFloat(productOption.price * (['SUPPORTER', 'CREW', 'EXTERNAL_CREW'].includes(orderItem.product.key) ? 0.5 : 1) * (productOption.tax + 1).toLocaleString('en-EN', {maximumFractionDigits: 2}));
                    }
                }
            }
            return current;
        }, 0) : 0;
        let lastProduct = "";
        let currentProductAmount = 0;

        return (
            <div className="LoginOptions">
                {this.state.showProductOptionInfo ? (
                    <ProductOptionInfo toggleProductOptionInfo={this.toggleProductOptionInfo} mode={this.state.showProductOptionInfoMode}/>
                ) : null}
                <LoginMenu active="options"/>
                {order ? (
                    <div className="order">
                        <div className="header">
                            <div className="line-left line-color-pink"/>
                            <a href="javascript:" onClick={this.doLogout}>
                                <IntlHtmlMessages id="login.overview.logout"/>
                            </a>
                        </div>
                        <div className="box box-2">
                            <div className="header-container">
                                <div className="line-left line-color-gray"/>
                                <div className="text">
                                    <p>
                                        <IntlMessages id="login.options.infoBusesAlreadyBooked"/>
                                    </p>
                                </div>
                            </div>
                            <div className="products">
                                {this.state.errors.subOrderItems ? (
                                    <div className="form-error">
                                        <IntlMessages
                                            id={`login.options.subOrderItems.error.${this.state.errors.subOrderItems}`}/>
                                    </div>
                                ) : null}
                                {this.state.order ? this.state.order.orderItems.map((orderItem, pIndex) => {
                                    let subOrderItem = subOrderItems.find((el) => el.key === orderItem.code);
                                    if (lastProduct === orderItem.product.key) {
                                        currentProductAmount += 1;
                                    } else {
                                        lastProduct = orderItem.product.key;
                                        currentProductAmount = 1;
                                    }
                                    return (
                                        <div className="product" key={pIndex}>
                                            <div className="product-description">
                                                <IntlHtmlMessages id="ticketing.step2.box2.products.optionsFor"/>&nbsp;
                                                <IntlHtmlMessages
                                                    id={`products.${orderItem.product.key}.titleWithTicket`}/>&nbsp;
                                                {currentProductAmount}
                                            </div>
                                            <div className="product-options">
                                                {this.props.ticketing.availableProducts.find((el) => el.key === orderItem.product.key).productOptions.filter((el) => !['HARDTICKET', 'GARBAGE_DEPOSIT'].includes(el.key)).map((availableProductOption, apoIndex) => {
                                                    let alreadySelected = orderItem.productOptions.find((el) => el.key === availableProductOption.key);
                                                    let selectionNotPossible = !!this.state.serverResponse; //orderItem.productOptions.filter((el) => el.key !== 'HARDTICKET').length > 0;
                                                    let selectedNow = !!(subOrderItem && subOrderItem.productOptionId === availableProductOption.id);
                                                    return (
                                                        <label className={`productOption${alreadySelected ? ' alreadySelected' : ''}`} key={`${pIndex}-${apoIndex}`}>
                                                            <input type="checkbox" disabled={alreadySelected || selectionNotPossible}
                                                                   checked={alreadySelected || selectedNow}
                                                                   onChange={() => this.handleToggleProductOption(orderItem.code, availableProductOption.id)}/>
                                                            <span className="price">
                                                        {(availableProductOption.price * (availableProductOption.tax + 1) * (['SUPPORTER', 'CREW', 'EXTERNAL_CREW'].includes(orderItem.product.key) ? 0.5 : 1)).toLocaleString('de-DE', {
                                                            maximumFractionDigits: 0,
                                                            minimumIntegerDigits: 1
                                                        })} €
                                                    </span>
                                                            <IntlHtmlMessages
                                                                id={`productOptions.${availableProductOption.key}.title`}/>&nbsp;
                                                            {availableProductOption.key === 'BUS_BERLIN' ? (
                                                                <button className="info-button" onClick={() => this.toggleProductOptionInfo('onlyBusBerlin')}>
                                                                    <img src={InfoIcon} alt="" className="default"/>
                                                                    <img src={InfoIconHover} alt="" className="mouseover"/>
                                                                </button>
                                                            ) : null}
                                                            {availableProductOption.key === 'BUS_GRUNOW' ? (
                                                                <button className="info-button" onClick={() => this.toggleProductOptionInfo('onlyBusGrunow')}>
                                                                    <img src={InfoIcon} alt="" className="default"/>
                                                                    <img src={InfoIconHover} alt="" className="mouseover"/>
                                                                </button>
                                                            ) : null}
                                                        </label>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )
                                }) : null}
                            </div>
                            <div className="totals-container">
                                <div className="line-left line-color-gray"/>
                                <div className={`options-total${totalProductOptionsAmount > 0 ? ' active' : ''}`}>
                                    <div className="text">
                                        <IntlHtmlMessages id="ticketing.step2.box2.optionsTotal"/>
                                    </div>
                                    <div className="number">
                                        {totalProductOptionsAmount.toLocaleString('de-DE', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            minimumIntegerDigits: 2
                                        })} €
                                    </div>
                                </div>
                                <div className="order-total">
                                    <div className="text">
                                        <IntlHtmlMessages id="ticketing.step2.box2.orderTotal"/>
                                    </div>
                                    <div className="number">
                                        {(oldTotalAmount + totalProductOptionsAmount).toLocaleString('de-DE', {
                                            minimumFractionDigits: 2,
                                            maximumFractionDigits: 2,
                                            minimumIntegerDigits: 2
                                        })} €
                                    </div>
                                </div>
                                <div className="including-tax">
                                    <IntlHtmlMessages id="ticketing.step2.box2.includingTax"/>
                                </div>
                            </div>
                            <div className="row row-6">
                                <div className="col">
                                    <div className="form-group">
                                        <label>
                                            <input className="input-radio" type="checkbox" checked={this.state.agb}
                                                   disabled={!!this.state.serverResponse}
                                                   onChange={(e) => this.setState({agb: e.target.checked})}/>
                                            <IntlMessages id="ticketing.step3.agb.1.label"/>
                                            <IntlMessages id="ticketing.step3.agb.2.label"/>
                                        </label>
                                        {this.state.errors.agb ? (
                                            <div className="form-error">
                                                <IntlMessages
                                                    id={`ticketing.step3.agb.error.${this.state.errors.agb}`}/>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            <input className="input-radio" type="checkbox" checked={this.state.privacy}
                                                   disabled={!!this.state.serverResponse}
                                                   onChange={(e) => this.setState({privacy: e.target.checked})}/>
                                            <IntlMessages id="ticketing.step3.privacy.1.label"/>
                                            <IntlMessages id="ticketing.step3.privacy.2.label"/>
                                        </label>
                                        {this.state.errors.privacy ? (
                                            <div className="form-error">
                                                <IntlMessages
                                                    id={`ticketing.step3.privacy.error.${this.state.errors.privacy}`}/>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            {!this.state.serverResponse ? (
                                <div className="login-options-submit">
                                    <Button
                                        titleKey="login.options.submit"
                                        onClick={this.handleSave}
                                    />
                                </div>
                            ) : null}
                        </div>
                        {this.state.serverResponse ? (
                            <div className="box box-paypal">
                                <div className="line-left line-color-whitegray"/>
                                <h2 className="title">
                                    <IntlHtmlMessages id="ticketing.step4.box4.title"/>
                                </h2>
                                <div className="text">
                                    <IntlHtmlMessages id="ticketing.step4.box4.text"/>
                                </div>
                                <div className="paypal-button-container">
                                    {<PaypalButton payment={this.state.serverResponse.paypalPaymentId} onCancel={null} onSuccess={this.handlePayment}/>}
                                </div>
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    login: state.login,
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {
    loadAvailableProducts, clearState, showLoader, hideLoader, logout
})(injectIntl(withRouter(LoginOptions)));
