import axios from 'axios';
import Config from '../config/Config';

class Ticketing {
    static getAvailableProducts() {
        return axios.get(`${Config.api()}ticketing/products/available`);
    }

    static createOrder(payload) {
        return axios.post(`${Config.api()}ticketing/order/create`, {
            ...payload
        });
    }

    static executePaypalPayment(payload) {
        return axios.post(`${Config.api()}ticketing/order/payment/paypal`, {
            ...payload
        });
    }

    static downloadTicket(payload) {
        return axios.post(`${Config.api()}ticketing/order/item/ticket`, {
            ...payload
        }, {
            responseType: 'blob'
        })
    }
}

export default Ticketing;