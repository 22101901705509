import React from 'react';
import connect from "react-redux/src/connect/connect";
import {hideLoader, showLoader} from "../../actions";

class ShowLoader extends React.Component {

    componentWillMount() {
        this.props.showLoader();
    }

    componentWillUnmount() {
        this.props.hideLoader();
    }

    render() {
        return null
    }
}

export default connect(null, {
    showLoader, hideLoader
})(ShowLoader);