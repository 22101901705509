import React from 'react';
import Lottie from 'react-lottie';
import connect from "react-redux/src/connect/connect";
import animationData from '../../assets/animations/loader.json';
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import {clearGlobalState, hideLoader} from "../../actions";
import Button from "./Button";

class Loader extends React.Component {
    render() {
        const {loader} = this.props;
        return (
            <div className={`Loader${loader.visible ? ' visible' : ''}${loader.showError ? ' hasError' : ''}`}>
                <div className="loader-inner">
                    <Lottie options={{
                        loop: true,
                        autoplay: true,
                        animationData: animationData,
                        rendererSettings: {
                            preserveAspectRatio: 'xMidYMid slice'
                        }
                    }}
                            width={240}
                            height={240}

                    />
                </div>
                {loader.showError ? (
                    <div className="loader-error">
                        <IntlHtmlMessages id="loader.error.text"/>
                        <div className="buttons">
                            <Button onClick={this.props.clearGlobalState} titleKey="loader.error.resetPage"/>
                            <Button onClick={this.props.hideLoader} titleKey="loader.error.tryAgain"/>
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    loader: state.loader
});

export default connect(mapStateToProps, {
    clearGlobalState, hideLoader
})(Loader);