import React, {Fragment} from 'react';
import logo from '../../assets/images/logo/logo_white.svg';
import logoMouseover from '../../assets/images/logo/logo_mouseover_white.svg';
import logoActive from '../../assets/images/logo/logo_onklick.svg';
import logoSmall from '../../assets/images/logo/entropie2019-logo-small.svg';
import {Link} from "react-router-dom";

class Logo extends React.Component {
    render() {
        return (
            <div className="Logo">
                <Link to="/">
                    {this.props.showFullLogo ? (
                        <Fragment>
                            <img src={logo} alt="" className="default"/>
                            <img src={logoMouseover} alt="" className="mouseover"/>
                            <img src={logoActive} alt="" className="active"/>
                        </Fragment>
                    ) : (
                        <img src={logoSmall} alt=""/>
                    )}
                </Link>
            </div>
        )
    }
}

export default Logo;
