import React from 'react';
import MixerRightImage from "../../assets/images/right/right-mixer.png";
import RightImage from "../../components/elements/RightImage";
import Logo from "../../components/elements/Logo";
import SocialMenu from "../../components/elements/SocialMenu";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import {loadAvailableProducts, clearState} from "../../actions";
import connect from "react-redux/src/connect/connect";
import TicketingStep1 from "./TicketingStep1";
import TicketingStep2 from "./TicketingStep2";
import TicketingStep3 from "./TicketingStep3";
import TicketingStep7 from "./TicketingStep7";
import TicketingStep4 from "./TicketingStep4";
import TicketingStep6 from "./TicketingStep6";
import TicketingStep5 from "./TicketingStep5";
import ProductOptionInfo from "./ProductOptionInfo";
import UsageAction from "../../components/elements/Usage/UsageAction";
import { enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock/lib/bodyScrollLock.es6';
import PodcastLink from "../../components/elements/PodcastLink";

class Ticketing extends React.Component {

    state = {
        showProductOptionInfo: false,
        showProductOptionInfoMode: null
    };

    targetElement = null;

    componentWillMount() {
        this.props.loadAvailableProducts();
    }

    componentWillReceiveProps(nextProps, nextState) {
        if(nextProps.ticketing.step !== this.props.ticketing.step) {
            window.scrollTo(0, 0);
        }
    }

    componentDidMount() {
        this.targetElement = document.getElementById('ProductOptionInfo');
    }

    componentWillUnmount() {
        this.props.clearState();
        clearAllBodyScrollLocks();
    }

    toggleProductOptionInfo = (mode) => {
        this.setState({
            showProductOptionInfo: !this.state.showProductOptionInfo,
            showProductOptionInfoMode: mode
        });
        if(this.state.showProductOptionInfo) {
            enableBodyScroll(this.targetElement);
        } else {
            //this.targetElement = document.getElementById('ProductOptionInfoText');
            //disableBodyScroll(this.targetElement);
        }
    };

    render() {
        const {ticketing} = this.props;
        return (
            <div className="Ticketing">
                <UsageAction action={`ticketing-step-${ticketing.step}`}/>
                {ticketing.loader ? (
                    <div className="loader"/>
                ) : null}
                <RightImage source={MixerRightImage}/>
                <div className="content-container">
                    <div className="content">
                        <Logo/>
                        <SocialMenu/>
                        <Footer/>
                        <Menu/>
                        {this.state.showProductOptionInfo ? (
                            <ProductOptionInfo toggleProductOptionInfo={this.toggleProductOptionInfo} mode={this.state.showProductOptionInfoMode}/>
                        ) : null}
                        <PodcastLink/>
                    </div>
                </div>
                <div className="left-text-column">
                    {ticketing.availableProducts && ticketing.availableProducts ? (
                        ticketing.step === 1 ? (
                            <TicketingStep1/>
                        ) : ticketing.step === 2 ? (
                            <TicketingStep2 toggleProductOptionInfo={this.toggleProductOptionInfo}/>
                        ) : ticketing.step === 3 ? (
                            <TicketingStep3/>
                        ) : ticketing.step === 4 ? (
                            <TicketingStep4/>
                        ) : ticketing.step === 5 ? (
                            <TicketingStep5/>
                        ) : ticketing.step === 6 ? (
                            <TicketingStep6/>
                        ) : ticketing.step === 7 ? (
                            <TicketingStep7/>
                        ) : (
                            null
                        )
                    ) : null}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {
    loadAvailableProducts, clearState
})(Ticketing);
