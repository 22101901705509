import React, {Fragment} from 'react';
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import connect from "react-redux/src/connect/connect";
import {injectIntl} from "react-intl";
import IntlMessages from "../../util/IntlMessages";
import Button from "../../components/elements/Button";
import FacebookIcon from '../../assets/images/social/facebook.svg';
import TicketingApi from '../../apis/Ticketing';
import fileDownload from 'js-file-download';
import {hideLoader, showLoader} from "../../actions";

class TicketingStep5 extends React.Component {

    downloadTicket = (oistac) => {
        this.props.showLoader();
        TicketingApi.downloadTicket({
            order: this.props.ticketing.orderSummary.oneTimeAccessKey,
            orderItem: oistac
        }).then((response) => {
            if (response.status === 200) {
                this.props.hideLoader();
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'unknown';
                if (contentDisposition) {
                    let filenameRegex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    const fileNameMatch = filenameRegex.exec(contentDisposition);
                    if (fileNameMatch.length > 2)
                        fileName = fileNameMatch[1];
                }
                fileDownload(response.data, fileName, 'application/pdf');
            }
        })
    };

    render() {
        const {ticketing} = this.props;
        let orderItemCount = {};
        return (
            <div className="TicketingStep5">
                <div className="step5-container">
                    <div className="line-left line-color-pink"/>
                    <div className="title">
                        <IntlHtmlMessages id="ticketing.step5.title"/>
                    </div>
                    <div className="text text-1">
                        <IntlHtmlMessages id="ticketing.step5.text1"/>
                    </div>
                    <div className="tickets">
                        {ticketing.orderSummary.orderItems.map((orderItem, oiIndex) => {
                            if (orderItemCount[orderItem.product.key]) {
                                orderItemCount[orderItem.product.key].count += 1
                            } else {
                                orderItemCount[orderItem.product.key] = {
                                    count: 1
                                }
                            }
                            return (
                                <div className="ticket" key={oiIndex}>
                                    <Button children={
                                        <Fragment>
                                            <IntlMessages id={`products.${orderItem.product.key}.titleWithTicket`}/>
                                            <span>{(orderItemCount[orderItem.product.key]).count > 1 ? (orderItemCount[orderItem.product.key]).count : ''}</span>
                                        </Fragment>
                                    } onClick={() => this.downloadTicket(orderItem.shortTimeAccessKey)}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="text text-2">
                        <IntlHtmlMessages id="ticketing.step5.text2"/>
                    </div>
                    <div className="buttons">
                        <Button isRealLink={true} to="https://facebook.com/entropiefestival" target="_blank" children={
                            <Fragment>
                                <img src={FacebookIcon} alt="Facebook"/>
                                <IntlMessages id="ticketing.step5.buttons.facebook"/>
                            </Fragment>
                        }/>
                        <Button titleKey="ticketing.step5.buttons.info" isLink={true} to="/info"/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {
    showLoader, hideLoader
})(injectIntl(TicketingStep5));
