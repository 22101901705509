import React from 'react';
import connect from "react-redux/src/connect/connect";
import {hideLoader, paypalError, paypalSuccess, showLoader} from "../../actions";
import {injectIntl} from "react-intl";
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import IntlMessages from "../../util/IntlMessages";
import PaypalButton from "./PaypalButton";
import TicketingApi from '../../apis/Ticketing';

class TicketingStep4 extends React.Component {
    state = {
        selectedProducts: [],
        order: {}
    };

    componentWillReceiveProps() {
        this.setState({
            selectedProducts: this.props.ticketing.selectedProducts,
            order: this.props.ticketing.order
        });
    }

    componentWillMount() {
        this.setState({
            selectedProducts: this.props.ticketing.selectedProducts,
            order: this.props.ticketing.order
        });
    }

    handlePayment = (data, actions) => {
        this.props.showLoader();
        TicketingApi.executePaypalPayment({
            order: this.props.ticketing.orderSummary.oneTimeAccessKey,
            paymentID: data.paymentID,
            payerID: data.payerID
        })
            .then((response) => {
                this.props.hideLoader();
                if(response.data.success && !response.data.data.paymentError) {
                    this.props.paypalSuccess(response.data.data);
                } else {
                    this.props.paypalError();
                }
            })
    };

    render() {
        let oldTotalAmount = this.state.selectedProducts.reduce((current, product) => {
            return current + Math.round(product.amount * (product.price * (product.tax + 1)));
        }, 0);
        let productOptionsTotals = [];
        let totalProductOptionsAmount = this.state.order.products.reduce((current, product) => {
            return current + product.product.productOptions.reduce((currentPO, po) => {
                if (po.selected) {
                    let productOptionTotal = productOptionsTotals.find((item) => (item.key === po.key));
                    if (productOptionTotal) {
                        productOptionTotal.amount++;
                    } else {
                        productOptionsTotals.push({
                            amount: 1,
                            key: po.key
                        });
                    }
                }
                return Math.round(currentPO + (po.selected ? parseFloat(po.price * (po.tax + 1).toLocaleString('en-EN', {maximumFractionDigits: 2})) : 0));
            }, 0)
        }, 0);

        const {ticketing} = this.props;

        return (
            <div className="TicketingStep4">
                <HeadingWithLine lineColor="pink" textKey="ticketing.step4.title" biggerPaddingBottom/>
                <div className="box box-1">
                    <div className="line-left line-color-blue"/>
                    <h2 className="title">
                        <IntlHtmlMessages id="ticketing.step1.box.title"/>
                    </h2>
                    <div className="text">
                        {this.state.selectedProducts.map((selectedProduct, spIndex) => {
                            if (selectedProduct.amount > 0) {
                                return (
                                    <div className="selectedProduct" key={spIndex}>
                                        {selectedProduct.amount} x <IntlMessages
                                        id={`products.${selectedProduct.key}.titleWithTicket`}/>,
                                    </div>
                                )
                            } return null;
                        })}
                        <br/>
                        <IntlMessages id="ticketing.step2.box1.total"/>
                        {oldTotalAmount.toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            minimumIntegerDigits: 2
                        })} €
                    </div>
                </div>
                <div className="box box-2">
                    <div className="header-container">
                        <div className="line-left line-color-blue"/>
                        <h2 className="title">
                            <IntlHtmlMessages id="ticketing.step3.box2.title"/>
                        </h2>
                        <div className="text">
                            {productOptionsTotals.length > 0 ? (
                                <div className="options">
                                    <div className="plus">
                                        +
                                    </div>
                                    {productOptionsTotals.map((pot, potKey) => {
                                        return (
                                            <div className="product-option" key={potKey}>
                                                {pot.amount} x <IntlHtmlMessages
                                                id={`productOptions.${pot.key}.title`}/>,
                                            </div>
                                        );
                                    })}
                                    <br/>
                                    <IntlHtmlMessages id="ticketing.step3.box2.sum"/>
                                    &nbsp;{(oldTotalAmount + totalProductOptionsAmount).toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })} €
                                </div>
                            ) : (
                                <div>
                                    <IntlHtmlMessages id="ticketing.step3.box2.none"/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="box box-3">
                    <div className="header-container">
                        <div className="line-left line-color-blue"/>
                        <h2 className="title">
                            <IntlHtmlMessages id="ticketing.step4.box3.title"/>
                        </h2>
                        <div className="text">
                            {ticketing.orderSummary.client.firstname} {ticketing.orderSummary.client.lastname} - {ticketing.orderSummary.orderNumber}
                        </div>
                    </div>
                </div>
                <div className="box box-4">
                    <div className="line-left line-color-whitegray"/>
                    <h2 className="title">
                        <IntlHtmlMessages id="ticketing.step4.box4.title"/>
                    </h2>
                    <div className="text">
                        <IntlHtmlMessages id="ticketing.step4.box4.text"/>
                    </div>
                    <div className="paypal-button-container">
                        {<PaypalButton payment={ticketing.orderSummary.paypalPaymentId} onCancel={null} onSuccess={this.handlePayment}/>}
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {
    paypalSuccess, paypalError, showLoader, hideLoader
})(injectIntl(TicketingStep4));