import React from 'react';
import {Route, Switch} from "react-router";
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import Logo from "../../components/elements/Logo";
import SocialMenu from "../../components/elements/SocialMenu";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import DrumsRightImage from "../../assets/images/right/right-drums.jpg";
import RightImage from "../../components/elements/RightImage";
import ImprintText from "./ImprintText";
import AgbText from "./AgbText";
import PrivacyText from "./PrivacyText";
import ImgArrowUp from "../../assets/images/ticketing/arrow-up.svg";
import ImgArrowUpDisabled from "../../assets/images/ticketing/arrow-up-disabled.svg";
import PodcastLink from "../../components/elements/PodcastLink";

class Legals extends React.Component {

    backToTop = () => {
        window.scrollTo(0, 0);
    };

    render() {
        return (
            <div className="Legals">
                <RightImage source={DrumsRightImage}/>
                <div className="content-container">
                    <div className="content">
                        <Logo/>
                        <SocialMenu/>
                        <Footer/>
                        <Menu/>
                        <PodcastLink/>
                    </div>
                </div>
                <div className="left-text-column">
                    <div className="line-left"/>
                    <Switch>
                        <Route path="/legals/agb" render={() => (
                            <div>
                                <HeadingWithLine lineColor="pink" textKey="legals.agb.title"/>
                                <AgbText/>
                            </div>
                        )}/>
                        <Route path="/legals/privacy" render={() => (
                            <div className="privacy">
                                <HeadingWithLine lineColor="pink" textKey="legals.privacy.title"/>
                                <PrivacyText/>
                            </div>
                        )}/>
                        <Route path="/legals" render={() => (
                            <div className="imprint">
                                <HeadingWithLine lineColor="pink" textKey="legals.imprint.title"/>
                                <ImprintText/>
                            </div>
                        )}/>
                    </Switch>
                    <a className="backToTop" onClick={this.backToTop}>
                            <img src={ImgArrowUp} alt="" className="default"/>
                            <img src={ImgArrowUpDisabled} alt="" className="alternative"/>
                    </a>
                </div>
            </div>
        )
    }
}

export default Legals;