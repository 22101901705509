import {POPUP_2022_HIDE, POPUP_2022_SHOW} from "../config/actions";
import {clearAllBodyScrollLocks, disableBodyScroll} from "body-scroll-lock/lib/bodyScrollLock.es6";

export const showPopup2022 = () => {
    disableBodyScroll(document.getElementById('Popup2022'))
    return {
        type: POPUP_2022_SHOW
    }
};

export const hidePopup2022 = () => {
    clearAllBodyScrollLocks();
    return {
        type: POPUP_2022_HIDE
    }
};