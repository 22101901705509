import {LOADER_HIDE, LOADER_SHOW, LOADER_SHOW_ERROR} from "../config/actions";
import {store} from '../store';

let loaderTimeout = null;

export const showLoader = () => {
    loaderTimeout = setTimeout(() => {
        store.dispatch({
            type: LOADER_SHOW_ERROR
        });
    }, 30000);
    return {
        type: LOADER_SHOW
    }
};

export const hideLoader = () => {
    if(loaderTimeout) clearInterval(loaderTimeout);
    return {
        type: LOADER_HIDE
    }
};

export const showLoaderError = () => ({
    type: LOADER_SHOW_ERROR
});