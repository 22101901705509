import React from 'react';
import ReactDOM from 'react-dom';
import App from './app/index';
import * as serviceWorker from './serviceWorker';
import history from './history';
import {Provider} from 'react-redux';
import {store} from './store';


history.listen(location => {
    //console.log(location);
    window.scrollTo(0, 0);
});

//export const store = store();

ReactDOM.render(<Provider store={store}><App history={history}/></Provider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
