import React from 'react';

class ImageWithLine extends React.Component {
    render() {
        return (
            <div className={`ImageWithLine${!!this.props.withBigShadow ? ' ImageWithLine-withBigShadow' : ''}`}>
                <div className={`line-left line-color-${this.props.lineColor}`} />
                <img src={this.props.imageSource} alt=""/>
            </div>
        )
    }
}

export default ImageWithLine;