import React from 'react';
import {Link} from "react-router-dom";
import IntlHtmlMessages from "../../../util/IntlHtmlMessages";

class LoginMenu extends React.Component {
    render() {
        const {active} = this.props;
        return (
            <div className="LoginMenu">
                <div className="line-left"/>
                <Link to="/login/overview" className={`${active === 'overview' ? 'active' : ''}`}>
                    <IntlHtmlMessages id="login.menu.overview"/>
                </Link>
                <Link to="/login/options" className={`${active === 'options' ? 'active' : ''}`}>
                    <IntlHtmlMessages id="login.menu.options"/>
                </Link>
                <Link to="/login/buses" className={`${active === 'buses' ? 'active' : ''}`}>
                    <IntlHtmlMessages id="login.menu.buses"/>
                </Link>
            </div>
        )
    }
}

export default LoginMenu;
