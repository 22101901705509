import React from 'react';
import connect from "react-redux/src/connect/connect";
import {withRouter} from "react-router";
import {hideLoader, supporterLogout} from "../../actions";
import SupporterApi from "../../apis/Supporter";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import IntlMessages from "../../util/IntlMessages";
import Button from "../../components/elements/Button";

class SupporterAccount extends React.Component {

    state = {
        workTypes: [],
        workTypesError: false,
        workTypesSuccessMessage: false
    };

    saveWorkTypesTimeoutHandler = null;
    hideWorkTypesSuccessMessageTimeoutHandler = null;

    componentWillMount() {
        if (!this.props.supporter.bearerToken.length > 0) {
            this.props.history.push('/supporter');
        }
    }

    componentWillUnmount() {
        this.props.supporterLogout();
    }

    componentDidMount() {
        this.loadWorkTypes();

    }

    loadWorkTypes = () => {
        SupporterApi.getWorktypes(this.props.supporter.bearerToken)
            .then((response) => {
                this.props.hideLoader();
                if (response.status === 200) {
                    this.setState({
                        workTypes: response.data.data,
                        workTypesError: false
                    });
                } else {
                    this.setState({
                        workTypesError: true
                    });
                }
            })
            .catch((error) => {
                this.props.hideLoader();
                this.setState({
                    workTypesError: true
                });
            });
    };

    handleToggleWorkType = (workTypeId) => {
        let workTypes = this.state.workTypes;
        workTypes.map((workType) => {
            if (workType.id === workTypeId) workType.chosen = !workType.chosen
            return workType;
        });
        this.setState({
            workTypes: workTypes
        });
        this.scheduleSaveWorkTypes();
    };

    scheduleSaveWorkTypes = () => {
        if (this.saveWorkTypesTimeoutHandler) clearTimeout(this.saveWorkTypesTimeoutHandler);
        this.saveWorkTypesTimeoutHandler = window.setTimeout(() => {
            this.saveWorkTypes();
        }, 2000);
    };

    saveWorkTypes = () => {
        let chosenWorkTypeIds = this.state.workTypes.filter((el) => el.chosen === true).map((el) => el.id);
        SupporterApi.setWorktypes(this.props.supporter.bearerToken, chosenWorkTypeIds)
            .then((response) => {
                if (response.status === 200) {
                    this.setState({
                        workTypes: response.data.data,
                        workTypesError: false,
                        workTypesSuccessMessage: true
                    });
                    this.scheduleHideWorkTypesSuccessMessage();
                } else {
                    this.setState({
                        workTypesError: true
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    workTypesError: true
                });
            });
    };

    scheduleHideWorkTypesSuccessMessage = () => {
        if (this.hideWorkTypesSuccessMessageTimeoutHandler) clearTimeout(this.hideWorkTypesSuccessMessageTimeoutHandler)
        this.hideWorkTypesSuccessMessageTimeoutHandler = window.setTimeout(() => {
            this.setState({
                workTypesSuccessMessage: false
            });
        }, 3000);
    };

    render() {
        const {supporter} = this.props;
        return (
            <div className="SupporterAccount">
                <div className="left-text-column">
                    <HeadingWithLine textKey="supporter.account.hello"
                                     textValues={{firstname: supporter.firstname, lastname: supporter.lastname}}
                                     lineColor="pink"/>
                    <div className="workTypesForm">
                        <div className="line-left line-color-pink"/>
                        <div className="text-container">
                            <div className="form-header">
                                <b><IntlMessages id="supporter.account.workTypes.title"/>:</b>
                                {this.state.workTypesError ? (
                                    <div className="form-error">
                                        <IntlMessages id="supporter.account.workTypes.form.error"/>
                                    </div>
                                ) : null}
                                <div className="form-success-message">
                                    {this.state.workTypesSuccessMessage ? (
                                        <IntlMessages id="supporter.account.workTypes.form.successMessage"/>
                                    ) : (
                                        <span>&nbsp;</span>
                                    )}
                                </div>
                            </div>
                            <div className="form-entries">
                                {this.state.workTypes.map((workType, wtIndex) => {
                                    return (
                                        <label className="workType" key={wtIndex}>
                                            <input type="checkbox" checked={workType.chosen === true}
                                                   onChange={() => this.handleToggleWorkType(workType.id)}/>
                                            <span>
                                                <IntlMessages id={`workType.${workType.key}`}/>&nbsp;
                                            </span>
                                        </label>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                    <div className="info-container">
                        <div className="line-left line-color-pink"/>
                        <div className="text-container">
                            <div className="info-1">
                                <IntlHtmlMessages id="supporter.account.info.1"/>
                            </div>
                            <div className="info-2">
                                <IntlHtmlMessages id="supporter.account.info.2"/>
                            </div>
                        </div>
                    </div>
                    <Button isLink={true} to="/supporter" titleKey="supporter.account.logout"/>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    supporter: state.supporter
});

export default connect(mapStateToProps, {
    supporterLogout, hideLoader
})(withRouter(SupporterAccount));