import axios from 'axios';
import Config from '../config/Config';

class Login {
    static authenticate(email, code) {
        return axios.post(`${Config.api()}login`, {
            username: email,
            password: code
        })
    }

    static loadOverview(bearerToken) {
        return axios.get(`${Config.api()}ticketLogin`, {
            headers: {
                authorization: `Bearer ${bearerToken}`
            }
        })
    }

    static printTicket(bearerToken, orderItem) {
        return axios.post(`${Config.api()}ticketLogin/ticket/print`, {
            orderItem
        }, {
            responseType: 'blob',
            headers: {
                authorization: `Bearer ${bearerToken}`
            },
        })
    }

    static createSubOrder(bearerToken, payload) {
        return axios.post(`${Config.api()}ticketLogin/subOrder/create`, {
            ...payload
        }, {
            headers: {
                authorization: `Bearer ${bearerToken}`
            }
        });
    }

    static executePaypalPayment(bearerToken, payload) {
        return axios.post(`${Config.api()}ticketLogin/subOrder/payment/paypal`, {
            ...payload
        }, {
            headers: {
                authorization: `Bearer ${bearerToken}`
            }
        });
    }

    static getAvailableShuttleBuses(bearerToken) {
        return axios.get(`${Config.api()}ticketLogin/buses`, {
            headers: {
                authorization: `Bearer ${bearerToken}`
            }
        })
    }

    static saveShuttleBuses(bearerToken, orderItemCode, shuttleBusArrival, shuttleBusReturn) {
        return axios.put(`${Config.api()}ticketLogin/buses/save`, {
            orderItem: orderItemCode,
            shuttleBusArrival,
            shuttleBusReturn
        }, {
            headers: {
                authorization: `Bearer ${bearerToken}`
            }
        })
    }
}

export default Login;
