import React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import paypal from 'paypal-checkout';
import Config from '../../config/Config';

const ReactButton = paypal.Button.driver('react', { React, ReactDOM });

class PaypalButton extends React.Component {
    constructor(props) {
        super(props);
        window.React = React;
        window.ReactDOM = ReactDOM;
        this.state = {
            showButton: true,
            env: Config.paypal().env, // Or 'sandbox'
            client: {
                sandbox:    'AThazvcc6Jjc62-4mL1nzhd8KY1P5sC1mcK27_sA87uEA5t2eGK3-Od2wDSsisDBNoo-20jYXGYGDsuj', // sandbox client ID
                production: 'AVf7ez39KAmQ_h1GbjnceGgDWqeAduju5ewbxI4jVxRqRyg52Tx5OK9qnBLV93SK7wjdfULQKoq7h0TU' // production client ID
            },
            commit: true, // Show a 'Pay Now' button
        };
    }

    render() {
        const {payment, onSuccess, onCancel} = this.props;
        return (<div><ReactButton
            env={this.state.env}
            client={this.state.client}
            payment={() => payment}
            commit
            onAuthorize={onSuccess}
            onCancel={onCancel}
            style={{layout: 'vertical'}}
            fundings={{allowed: [paypal.FUNDING.CARD, paypal.FUNDING.CREDIT, paypal.FUNDING.ELV]}}
        /></div>);
    }
}

PaypalButton.propTypes = {
    payment: PropTypes.string.isRequired,
};

PaypalButton.defaultProps = {
    env: 'sandbox',
    onSuccess: (payment) => {
        console.log('The payment was succeeded!', payment);
    },
    onCancel: (data) => {
        console.log('The payment was cancelled!', data);
    },
    onError: (err) => {
        console.log('Error loading Paypal script!', err);
    },
};

export default PaypalButton;
//export default scriptLoader('https://www.paypalobjects.com/api/checkout.js')(PaypalButton);