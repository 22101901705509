import React from 'react';
import connect from "react-redux/src/connect/connect";
import {backToStep1, saveStep2} from "../../actions";
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import TicketingStepProgress from "./TicketingStepProgress";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import IntlMessages from "../../util/IntlMessages";
import Button from "../../components/elements/Button";
import InfoIcon from "../../assets/images/ticketing/Info.svg";
import InfoIconHover from "../../assets/images/ticketing/Info_Hover.svg";

class TicketingStep2 extends React.Component {
    state = {
        selectedProducts: [],
        order: null
    };

    componentWillReceiveProps() {
        this.setData();
    }

    componentWillMount() {
        this.setData();
    }

    setData = () => {
        let order = {...this.props.ticketing.order};
        order.products.map((product) => {
            product.product.productOptions = product.product.productOptions.map((productOption) => {
                if(productOption.key === 'GARBAGE_DEPOSIT') {
                    productOption.selected = true;
                }
                return productOption;
            });
        });
        this.setState({
            selectedProducts: this.props.ticketing.selectedProducts,
            order: order
        });
    };

    handleToggleProductOption = (productElementId, productOptionId) => {
        let order = {...this.state.order};
        order.products.map((product) => {
            if(product.id === productElementId) {
                product.product.productOptions = product.product.productOptions.map((productOption) => {
                    if(productOption.id === productOptionId) {
                        productOption.selected = !productOption.selected
                    }
                    if(productOption.selected && productOption.type) {
                        product.product.productOptions.map((po) => {
                            if(po.id !== productOption.id && po.type && po.type === productOption.type && po.selected) {
                                po.selected = false;
                            }
                        })
                    }
                    return productOption;
                })
            }
        });
        this.setState({
            order: order
        });
    };

    handleToggleHardtickets = () => {
        let order = {...this.state.order};
        order.hardtickets = !order.hardtickets;
        order.products.map((product) => {
            product.product.productOptions.map((productOption) => {
                if(productOption.key === 'HARDTICKET') {
                    productOption.selected = order.hardtickets;
                }
            });
        });
        this.setState({
            order: order
        })
    };

    handleSave = () => {
        this.props.saveStep2(this.state.order);
    };

    render() {
        let oldTotalAmount = this.state.selectedProducts.reduce((current, product) => {
            return current + Math.round(product.amount * (product.price * (product.tax + 1)));
        }, 0);
        let totalProductOptionsAmount = this.state.order.products.reduce((current, product) => {
            return current + product.product.productOptions.reduce((currentPO, po) => {
                return Math.round(currentPO + (po.selected ? parseFloat(po.price*(po.tax+1).toLocaleString('en-EN', {maximumFractionDigits: 2})) : 0));
            }, 0)
        }, 0);
        let lastProduct = "";
        let currentProductAmount = 0;

        return (
            <div className="TicketingStep2">
                <HeadingWithLine lineColor="pink" textKey="ticketing.step2.title" biggerPaddingBottom/>
                <TicketingStepProgress activeStep={2}/>
                <div className="box box-1" onClick={this.props.backToStep1}>
                    <div className="line-left line-color-blue"/>
                    <h2 className="title">
                        <IntlHtmlMessages id="ticketing.step1.box.title"/>
                    </h2>
                    <div className="text">
                        {this.state.selectedProducts.map((selectedProduct, spIndex) => {
                            if (selectedProduct.amount > 0) {
                                return (
                                    <div className="selectedProduct" key={spIndex}>
                                        {selectedProduct.amount} x <IntlMessages
                                        id={`products.${selectedProduct.key}.titleWithTicket`}/>,
                                    </div>
                                )
                            }
                        })}
                        <br/><IntlMessages id="ticketing.step2.box1.total"/>&nbsp;
                        {oldTotalAmount.toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            minimumIntegerDigits: 2
                        })} €
                    </div>
                </div>
                <div className="box box-2">
                    <div className="header-container">
                        <div className="line-left line-color-gray"/>
                        <h2 className="title">
                            <IntlHtmlMessages id="ticketing.step2.box2.title"/>
                        </h2>
                        <div className="text">
                            <IntlHtmlMessages id="ticketing.step2.box2.text"/>
                            <Button onClick={this.props.toggleProductOptionInfo} titleKey="ticketing.step2.box2.link">
                                <IntlMessages id="ticketing.step2.box2.link1"/>
                                <IntlMessages id="ticketing.step2.box2.link2"/>
                            </Button>
                        </div>
                    </div>
                    <div className="products">
                        {this.state.order ? this.state.order.products.map((product, pIndex) => {

                            if(lastProduct === product.product.key) {
                                currentProductAmount += 1;
                            } else {
                                lastProduct = product.product.key;
                                currentProductAmount = 1;
                            }
                            return (
                                <div className="product" key={pIndex}>
                                    <div className="product-description">
                                        <IntlHtmlMessages id="ticketing.step2.box2.products.optionsFor"/>&nbsp;
                                        <IntlHtmlMessages id={`products.${product.product.key}.titleWithTicket`}/>&nbsp;
                                        {currentProductAmount}
                                    </div>
                                    <div className="product-options">
                                        {product.product.productOptions.filter((el) => el.key !== 'HARDTICKET').map((productOption, poIndex) => {
                                            return (
                                                <label className={`productOption${productOption.key === 'GARBAGE_DEPOSIT' ? ' alreadySelected' : ''}`} key={`${pIndex}-${poIndex}`}>
                                                    <input type="checkbox" checked={productOption.selected === true || productOption.key === 'GARBAGE_DEPOSIT'} onChange={() => this.handleToggleProductOption(product.id, productOption.id)} disabled={productOption.key === 'GARBAGE_DEPOSIT'}/>
                                                    <span className="price">
                                                        {(productOption.price*(productOption.tax + 1)).toLocaleString('de-DE', {maximumFractionDigits: 0, minimumIntegerDigits: 1})} €
                                                    </span>
                                                    <IntlHtmlMessages id={`productOptions.${productOption.key}.title`}/>&nbsp;
                                                    {productOption.key === 'GARBAGE_DEPOSIT' ? (
                                                        <button className="info-button" onClick={() => this.props.toggleProductOptionInfo('onlyGarbageDeposit')}>
                                                            <img src={InfoIcon} alt="" className="default"/>
                                                            <img src={InfoIconHover} alt="" className="mouseover"/>
                                                        </button>
                                                    ) : null}
                                                    {productOption.key === 'BUS_BERLIN' ? (
                                                        <button className="info-button" onClick={() => this.props.toggleProductOptionInfo('onlyBusBerlin')}>
                                                            <img src={InfoIcon} alt="" className="default"/>
                                                            <img src={InfoIconHover} alt="" className="mouseover"/>
                                                        </button>
                                                    ) : null}
                                                    {productOption.key === 'BUS_GRUNOW' ? (
                                                        <button className="info-button" onClick={() => this.props.toggleProductOptionInfo('onlyBusGrunow')}>
                                                            <img src={InfoIcon} alt="" className="default"/>
                                                            <img src={InfoIconHover} alt="" className="mouseover"/>
                                                        </button>
                                                    ) : null}
                                                </label>
                                            )
                                        })}
                                    </div>
                                </div>
                            )
                        }) : null}
                    </div>
                    <div className="totals-container">
                        <div className="line-left line-color-gray"/>
                        <div className="hardticket-container">
                            <label>
                                <input type="checkbox" checked={this.state.order.hardtickets} onChange={this.handleToggleHardtickets}/>
                                <span className="price">
                                    <IntlHtmlMessages id="ticketing.step2.box2.hardtickets.price"/>
                                </span>
                                <IntlHtmlMessages id="ticketing.step2.box2.hardtickets.label"/>
                            </label>
                        </div>
                        <div className="old-total">
                            <div className="text">
                                <IntlHtmlMessages id="ticketing.step2.box2.oldTotal"/>
                            </div>
                            <div className="number">
                                {oldTotalAmount.toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    minimumIntegerDigits: 2
                                })} €
                            </div>
                        </div>
                        <div className={`options-total${totalProductOptionsAmount > 0 ? ' active': ''}`}>
                            <div className="text">
                                <IntlHtmlMessages id="ticketing.step2.box2.optionsTotal"/>
                            </div>
                            <div className="number">
                                {totalProductOptionsAmount.toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    minimumIntegerDigits: 2
                                })} €
                            </div>
                        </div>
                        <div className="order-total">
                            <div className="text">
                                <IntlHtmlMessages id="ticketing.step2.box2.orderTotal"/>
                            </div>
                            <div className="number">
                                {(oldTotalAmount+totalProductOptionsAmount).toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2,
                                    minimumIntegerDigits: 2
                                })} €
                            </div>
                        </div>
                        <div className="including-tax">
                            <IntlHtmlMessages id="ticketing.step2.box2.includingTax"/>
                        </div>
                    </div>
                    <div className="ticketing-step2-submit">
                        <Button
                            titleKey="ticketing.step2.submit"
                            onClick={this.handleSave}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {
    saveStep2, backToStep1
})(TicketingStep2);
