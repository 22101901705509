import React, {Fragment} from 'react';
import connect from "react-redux/src/connect/connect";
import {injectIntl} from 'react-intl';
import {backToStep1, backToStep2, hideLoader, saveStep3, showLoader} from "../../actions";
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import TicketingStepProgress from "./TicketingStepProgress";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import IntlMessages from "../../util/IntlMessages";
import Button from "../../components/elements/Button";
import TicketingApi from '../../apis/Ticketing';
import { CountryDropdown } from 'react-country-region-selector';


class TicketingStep3 extends React.Component {
    state = {
        selectedProducts: [],
        order: null,
        firstname: '',
        lastname: '',
        email: '',
        street: '',
        zipcode: '',
        city: '',
        country: 'Germany',
        agb: false,
        privacy: false,
        newsletter: false,
        paymentType: '',
        errors: {}
    };

    componentWillReceiveProps() {
        this.setState({
            selectedProducts: this.props.ticketing.selectedProducts,
            order: this.props.ticketing.order
        });
    }

    componentWillMount() {
        this.setState({
            selectedProducts: this.props.ticketing.selectedProducts,
            order: this.props.ticketing.order
        });
    }

    handleSave = () => {
        let products = this.state.order.products.map((product) => {
            return {
                product: product.productId,
                productOptions: product.product.productOptions.filter((item) => item.selected).map((po) => {
                    return {
                        productOption: po.id
                    }
                })
            }
        });
        let payload = {
            ...this.state,
            products
        };
        this.props.showLoader();
        TicketingApi.createOrder(payload)
            .then((response) => {
                this.props.hideLoader();
                if(!response.data.success) {
                    this.setState({
                        errors: response.data.errors
                    });
                } else {
                    this.props.saveStep3(response.data.data);
                }
            });
    };

    render() {
        let oldTotalAmount = this.state.selectedProducts.reduce((current, product) => {
            return current + Math.round(product.amount * (product.price * (product.tax + 1)));
        }, 0);
        let productOptionsTotals = [];
        let totalProductOptionsAmount = this.state.order.products.reduce((current, product) => {
            return current + product.product.productOptions.reduce((currentPO, po) => {
                if (po.selected) {
                    let productOptionTotal = productOptionsTotals.find((item) => (item.key === po.key));
                    if (productOptionTotal) {
                        productOptionTotal.amount++;
                    } else {
                        productOptionsTotals.push({
                            amount: 1,
                            key: po.key
                        });
                    }
                }
                return Math.round(currentPO + (po.selected ? parseFloat(po.price * (po.tax + 1).toLocaleString('en-EN', {maximumFractionDigits: 2})) : 0));
            }, 0)
        }, 0);

        const {intl} = this.props;
        return (
            <div className="TicketingStep3">
                <HeadingWithLine lineColor="pink" textKey="ticketing.step3.title" biggerPaddingBottom/>
                <TicketingStepProgress activeStep={3}/>
                <div className="box box-1" onClick={this.props.backToStep1}>
                    <div className="line-left line-color-blue"/>
                    <h2 className="title">
                        <IntlHtmlMessages id="ticketing.step1.box.title"/>
                    </h2>
                    <div className="text">
                        {this.state.selectedProducts.map((selectedProduct, spIndex) => {
                            if (selectedProduct.amount > 0) {
                                return (
                                    <div className="selectedProduct" key={spIndex}>
                                        {selectedProduct.amount} x <IntlMessages
                                        id={`products.${selectedProduct.key}.titleWithTicket`}/>,
                                    </div>
                                )
                            }
                        })}
                        <br/>
                        <IntlMessages id="ticketing.step2.box1.total"/>&nbsp;
                        {oldTotalAmount.toLocaleString('de-DE', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                            minimumIntegerDigits: 2
                        })} €
                    </div>
                </div>
                <div className="box box-2" onClick={this.props.backToStep2}>
                    <div className="header-container">
                        <div className="line-left line-color-blue"/>
                        <h2 className="title">
                            <IntlHtmlMessages id="ticketing.step3.box2.title"/>
                        </h2>
                        <div className="text">
                            {productOptionsTotals.length > 0 ? (
                                <div className="options">
                                    <div className="plus">
                                        +
                                    </div>
                                    {productOptionsTotals.map((pot, potKey) => {
                                        return (
                                            <div className="product-option" key={potKey}>
                                                {pot.amount} x <IntlHtmlMessages
                                                id={`productOptions.${pot.key}.title`}/>,
                                            </div>
                                        );
                                    })}
                                    <br/>
                                    <IntlHtmlMessages id="ticketing.step3.box2.sum"/>
                                    &nbsp;{(oldTotalAmount + totalProductOptionsAmount).toLocaleString('de-DE', {
                                    minimumFractionDigits: 2,
                                    maximumFractionDigits: 2
                                })} €
                                </div>
                            ) : (
                                <div>
                                    <IntlHtmlMessages id="ticketing.step3.box2.none"/>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="box box-3">
                    <div className="header-container">
                        <div className="line-left line-color-gray"/>
                        <h2 className="title">
                            <IntlHtmlMessages id="ticketing.step3.box3.title"/>
                        </h2>
                        <div className="text">
                            <IntlHtmlMessages id="ticketing.step3.box3.text"/>
                        </div>
                        <div className="form">
                            <div className="row row-1">
                                <div className="col form-group">
                                    <input className="input-field" type="text" value={this.state.firstname}
                                           onChange={(e) => this.setState({firstname: e.target.value})}
                                           placeholder={intl.formatMessage({id: "ticketing.step3.firstname.label"})}/>
                                    {this.state.errors.firstname ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`ticketing.step3.firstname.error.${this.state.errors.firstname}`}/>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col form-group">
                                    <input className="input-field" type="text" value={this.state.lastname}
                                           onChange={(e) => this.setState({lastname: e.target.value})}
                                           placeholder={intl.formatMessage({id: "ticketing.step3.lastname.label"})}/>
                                    {this.state.errors.lastname ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`ticketing.step3.lastname.error.${this.state.errors.lastname}`}/>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row row-2">
                                <div className="col form-group">
                                    <input className="input-field" type="text" value={this.state.email}
                                           onChange={(e) => this.setState({email: e.target.value})}
                                           placeholder={intl.formatMessage({id: "ticketing.step3.email.label"})}/>
                                    {this.state.errors.email ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`ticketing.step3.email.error.${this.state.errors.email}`}/>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row row-3">
                                <div className="col form-group">
                                    <input className="input-field" type="text" value={this.state.street}
                                           onChange={(e) => this.setState({street: e.target.value})}
                                           placeholder={intl.formatMessage({id: "ticketing.step3.street.label"})}/>
                                    {this.state.errors.street ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`ticketing.step3.street.error.${this.state.errors.street}`}/>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row row-4">
                                <div className="col form-group">
                                    <input className="input-field" type="text" value={this.state.zipcode}
                                           onChange={(e) => this.setState({zipcode: e.target.value})}
                                           placeholder={intl.formatMessage({id: "ticketing.step3.zipcode.label"})}/>
                                    {this.state.errors.zipcode ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`ticketing.step3.zipcode.error.${this.state.errors.zipcode}`}/>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col form-group">
                                    <input className="input-field" type="text" value={this.state.city}
                                           onChange={(e) => this.setState({city: e.target.value})}
                                           placeholder={intl.formatMessage({id: "ticketing.step3.city.label"})}/>
                                    {this.state.errors.city ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`ticketing.step3.city.error.${this.state.errors.city}`}/>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row row-5">
                                <div className="col form-group">
                                    <CountryDropdown className="input-select"
                                         value={this.state.country}
                                         defaultOptionLabel={intl.formatMessage({id: "ticketing.step3.country.label"})}
                                         onChange={(val) => this.setState({country: val})} />
                                    <input className="input-field" type="text" value={this.state.country}
                                           onChange={(e) => this.setState({country: e.target.value})}
                                           placeholder={intl.formatMessage({id: "ticketing.step3.country.label"})}/>
                                    {this.state.errors.country ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`ticketing.step3.country.error.${this.state.errors.country}`}/>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                            <div className="row row-6">
                                <div className="col">
                                    <div className="form-group">
                                        <label>
                                            <input className="input-radio" type="checkbox" checked={this.state.agb}
                                                   onChange={(e) => this.setState({agb: e.target.checked})}/>
                                            <IntlMessages id="ticketing.step3.agb.1.label"/>
                                            <IntlMessages id="ticketing.step3.agb.2.label"/>
                                        </label>
                                        {this.state.errors.agb ? (
                                            <div className="form-error">
                                                <IntlMessages
                                                    id={`ticketing.step3.agb.error.${this.state.errors.agb}`}/>
                                            </div>
                                        ) : null}
                                    </div>
                                    <div className="form-group">
                                        <label>
                                            <input className="input-radio" type="checkbox" checked={this.state.privacy}
                                                   onChange={(e) => this.setState({privacy: e.target.checked})}/>
                                            <IntlMessages id="ticketing.step3.privacy.1.label"/>
                                            <IntlMessages id="ticketing.step3.privacy.2.label"/>
                                        </label>
                                        {this.state.errors.privacy ? (
                                            <div className="form-error">
                                                <IntlMessages
                                                    id={`ticketing.step3.privacy.error.${this.state.errors.privacy}`}/>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className="col">
                                    <div className="form-group">
                                        <label>
                                            <input className="input-radio" type="checkbox" checked={this.state.newsletter}
                                                   onChange={(e) => this.setState({newsletter: e.target.checked})}/>
                                            <IntlMessages id="ticketing.step3.newsletter.1.label"/>
                                            <span className="light">
                                                <IntlMessages id="ticketing.step3.newsletter.2.label"/>
                                            </span>
                                        </label>
                                        {this.state.errors.newsletter ? (
                                            <div className="form-error">
                                                <IntlMessages
                                                    id={`ticketing.step3.newsletter.error.${this.state.errors.newsletter}`}/>
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                            <div className="row row-7">
                                <div className="col col-1">
                                    <IntlMessages id="ticketing.step3.paymentType.title"/>
                                    {this.state.errors.paymentType ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`ticketing.step3.paymentType.error.${this.state.errors.paymentType}`}/>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="col col-2">
                                    <div className="form-group">
                                        <label>
                                            <input className="input-radio" type="radio" name="paymentType" value="1"
                                                   checked={this.state.paymentType === 'PAYPAL'}
                                                   onChange={() => this.setState({paymentType: 'PAYPAL'})}/>
                                            <IntlMessages id="ticketing.step3.paymentType.paypal.label"/>
                                        </label>
                                    </div>
                                </div>
                                <div className="col col-3">
                                    <div className="form-group">
                                        <label>
                                            <input className="input-radio" type="radio" name="paymentType" value="2"
                                                   checked={this.state.paymentType === 'PREPAYMENT'}
                                                   onChange={() => this.setState({paymentType: 'PREPAYMENT'})}/>
                                            <IntlMessages id="ticketing.step3.paymentType.prepayment.label"/>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="ticketing-step3-submit">
                        <Button
                            titleKey="ticketing.step3.submit"
                            onClick={this.handleSave}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {
    saveStep3, backToStep1, backToStep2, showLoader, hideLoader
})(injectIntl(TicketingStep3));