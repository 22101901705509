import {
    GLOBAL_CLEAR_STATE,
    SUPPORTER_LOGOUT,
    SUPPORTER_SET_AUTHENTICATION
} from "../config/actions";

const INIT_STATE = {
    bearerToken: '',
    firstname: '',
    lastname: ''
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case SUPPORTER_SET_AUTHENTICATION: {
            return {
                ...state,
                bearerToken: action.payload.access_token,
                firstname: action.payload.firstname,
                lastname: action.payload.lastname
            }
        }
        case SUPPORTER_LOGOUT: {
            return {
                ...state,
                bearerToken: '',
                firstname: '',
                lastname: ''
            }
        }
        case GLOBAL_CLEAR_STATE: {
            return INIT_STATE;
        }
        default:
            return state;
    }
}