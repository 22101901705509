import React, {Fragment} from 'react';
import connect from "react-redux/src/connect/connect";
import {injectIntl} from "react-intl";
import LoginApi from '../../apis/Login';
import {hideLoader, logout, showLoader} from "../../actions";
import {withRouter} from "react-router";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import Button from "../../components/elements/Button";
import IntlMessages from "../../util/IntlMessages";
import fileDownload from "js-file-download";
import LoginMenu from "./components/LoginMenu";

class LoginOverview extends React.Component {

    state = {
        order: null
    };

    componentWillMount() {
        this.props.showLoader();
        LoginApi.loadOverview(this.props.login.bearerToken)
            .then((response) => {
                this.props.hideLoader();
                if(response.status === 200 && response.data.success) {
                    this.setState({order: response.data.data});
                } else {
                    this.props.history.push('/login');
                }
            })
            .catch((error) => {
                this.props.hideLoader();
                this.props.history.push('/login');
            })
    }

    doLogout = (e) => {
        e.preventDefault();
        this.props.history.push('/login');
        this.props.logout();
    };

    downloadTicket = (code) => {
        this.props.showLoader();
        LoginApi.printTicket(this.props.login.bearerToken, code).then((response) => {
            if (response.status === 200) {
                this.props.hideLoader();
                const contentDisposition = response.headers['content-disposition'];
                let fileName = 'unknown';
                if (contentDisposition) {
                    let filenameRegex = new RegExp(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
                    const fileNameMatch = filenameRegex.exec(contentDisposition);
                    if (fileNameMatch.length > 2)
                        fileName = fileNameMatch[1];
                }
                fileDownload(response.data, fileName, 'application/pdf');
            }
        })
    };

    render() {
        const { order } = this.state;
        let orderItemCount = {};
        return (
            <div className="LoginOverview">
                <LoginMenu active="overview"/>
                {order ? (
                    <div className="order">
                        <div className="header">
                            <div className="line-left line-color-pink"/>
                            <a href="javascript:" onClick={this.doLogout}>
                                <IntlHtmlMessages id="login.overview.logout"/>
                            </a>
                            {this.props.location.state && this.props.location.state.subOrderSuccess === true ? (
                                <div className="subOrderSuccess">
                                    <IntlHtmlMessages id="login.overview.subOrderSuccess"/>
                                </div>
                            ) : null}
                            <div className="title">
                                <IntlHtmlMessages id="login.overview.title"/>
                            </div>
                        </div>
                        <div className="tickets">
                            {order.orderItems.map((orderItem, oiIndex) => {
                                if (orderItemCount[orderItem.product.key]) {
                                    orderItemCount[orderItem.product.key].count += 1
                                } else {
                                    orderItemCount[orderItem.product.key] = {
                                        count: 1
                                    }
                                }
                                return (
                                    <div className="ticket" key={oiIndex}>
                                        <Button children={
                                            <Fragment>
                                                <IntlMessages id={`products.${orderItem.product.key}.titleWithTicket`}/>
                                                <span>{(orderItemCount[orderItem.product.key]).count > 1 ? (orderItemCount[orderItem.product.key]).count : ''}</span>
                                            </Fragment>
                                        } onClick={() => this.downloadTicket(orderItem.code)}/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                ) : null}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    login: state.login
});

export default connect(mapStateToProps, {
    showLoader, hideLoader, logout
})(injectIntl(withRouter(LoginOverview)));
