import {
    GLOBAL_CLEAR_STATE,
    LOGIN_LOGOUT,
    LOGIN_SET_BEARER_TOKEN
} from "../config/actions";

const INIT_STATE = {
    bearerToken: ''
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case LOGIN_SET_BEARER_TOKEN: {
            return {
                ...state,
                bearerToken: action.bearerToken
            }
        }
        case LOGIN_LOGOUT: {
            return {
                ...state,
                bearerToken: ''
            }
        }
        case GLOBAL_CLEAR_STATE: {
            return INIT_STATE;
        }
        default:
            return state;
    }
}