import React from 'react';
import Button from "../../components/elements/Button";

class HomeShortLinks extends React.Component {
    render() {
        return (
            <div className="HomeShortLinks">
                <Button isLink={true} to="/tickets" titleKey="home.shortLinks.tickets"/>
                <Button isLink={true} to="/supporter" titleKey="home.shortLinks.supporter"/>
            </div>
        )
    }
}

export default HomeShortLinks;