/* eslint-disable jsx-a11y/href-no-hash */
import {addLocaleData} from 'react-intl';
import deLang from './entries/de-DE';
import enLang from './entries/en-EN';

const AppLocale = {
    de: deLang,
    en: enLang
};
addLocaleData(AppLocale.de.data);
addLocaleData(AppLocale.en.data);

export default AppLocale;
