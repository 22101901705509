import {POPUP_2022_HIDE, POPUP_2022_SHOW} from "../config/actions";

const INIT_STATE = {
    popup2022Visible: true
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case POPUP_2022_SHOW:
            return {
                ...state,
                popup2022Visible: true
            };
        case POPUP_2022_HIDE:
            return {
                ...state,
                popup2022Visible: false
            };
        default:
            return state;
    }
}