import React, {Fragment} from 'react';
import closeImage from '../../assets/images/close.svg';
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import {clearAllBodyScrollLocks, disableBodyScroll} from "body-scroll-lock/lib/bodyScrollLock.es6";
import {connect} from "react-redux";
import moment from "moment";

class ProductOptionInfo extends React.Component {
    componentWillUnmount() {
        clearAllBodyScrollLocks();
    }
    componentDidMount() {
        disableBodyScroll(document.getElementById('ProductOptionInfoText'));
    }
    render() {
        return (
            <div className="ProductOptionInfo">
                <button className="closeButton" onClick={this.props.toggleProductOptionInfo}>
                    <img src={closeImage} alt=""/>
                </button>
                <div className="background-box"/>
                <div className="text-container" id="ProductOptionInfoText">
                    {this.props.mode === 'onlyGarbageDeposit' ? (
                        <IntlHtmlMessages id="ticketing.productOptionInfo.onlyGarbageDeposit.text"/>
                    ) : this.props.mode === 'onlyBusBerlin' ? (
                        <Fragment>
                            <IntlHtmlMessages id="ticketing.productOptionInfo.onlyBusBerlin.text"/>
                            {this.props.ticketing.availableProducts[0].bookableBuses.filter((el) => el.type === 'BUS_BERLIN').map((busMap, bMI) => (
                                <Fragment>
                                    <IntlHtmlMessages id="ticketing.productOptionInfo.onlyBusBerlin.arrivalTitle"/>
                                    {busMap.buses.filter((el) => el.direction === 'ARRIVAL').map((bus, bI) => (
                                        <div className="bookableBus" key={`${bMI}-${bI}`}>
                                            {moment(bus.startAt).format('DD.MM.YYYY, HH:mm')}
                                        </div>
                                    ))}
                                    <IntlHtmlMessages id="ticketing.productOptionInfo.onlyBusBerlin.returnTitle"/>
                                    {busMap.buses.filter((el) => el.direction === 'RETURN').map((bus, bI) => (
                                        <div className="bookableBus" key={`${bMI}-${bI}`}>
                                            {moment(bus.startAt).format('DD.MM.YYYY, HH:mm')}
                                        </div>
                                    ))}
                                </Fragment>
                            ))}
                        </Fragment>
                    ) : this.props.mode === 'onlyBusGrunow' ? (
                        <Fragment>
                            <IntlHtmlMessages id="ticketing.productOptionInfo.onlyBusGrunow.text"/>
                            {this.props.ticketing.availableProducts[0].bookableBuses.filter((el) => el.type === 'BUS_GRUNOW').map((busMap, bMI) => (
                                <Fragment>
                                    <IntlHtmlMessages id="ticketing.productOptionInfo.onlyBusGrunow.arrivalTitle"/>
                                    {busMap.buses.filter((el) => el.direction === 'ARRIVAL').map((bus, bI) => (
                                        <div className="bookableBus" key={`${bMI}-${bI}`}>
                                            {moment(bus.startAt).format('DD.MM.YYYY, HH:mm')}
                                        </div>
                                    ))}
                                    <IntlHtmlMessages id="ticketing.productOptionInfo.onlyBusGrunow.returnTitle"/>
                                    {busMap.buses.filter((el) => el.direction === 'RETURN').map((bus, bI) => (
                                        <div className="bookableBus" key={`${bMI}-${bI}`}>
                                            {moment(bus.startAt).format('DD.MM.YYYY, HH:mm')}
                                        </div>
                                    ))}
                                </Fragment>
                            ))}
                        </Fragment>
                    ) : (
                        <IntlHtmlMessages id="ticketing.productOptionInfo.text"/>
                    )}
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, null)(ProductOptionInfo);
