import {
    GLOBAL_CLEAR_STATE,
    SETTINGS_LANGUAGE_TOGGLE,
    SETTINGS_SET_LAST_ACTION,
    SETTINGS_SET_UUID
} from "../config/actions";

export const toggleLanguage = () => {
    window.setTimeout(() => window.location.reload(true), 10);
        return {
            type: SETTINGS_LANGUAGE_TOGGLE
        }
    }
;

export const setUuid = () => ({
    type: SETTINGS_SET_UUID
});

export const setLastAction = () => ({
    type: SETTINGS_SET_LAST_ACTION
});

export const clearGlobalState = () => ({
    type: GLOBAL_CLEAR_STATE
});