import React from 'react';
import UsageApi from '../../../apis/Usage';
import connect from "react-redux/src/connect/connect";
import {setLastAction} from "../../../actions";

class UsageAction extends React.Component {

    componentDidMount() {
        UsageApi.sendAction(this.props.settingsUuid, this.props.action);
    }

    componentWillUpdate(nextProps, nextState) {
        UsageApi.sendAction(this.props.settingsUuid, nextProps.action, this.props.action);
    }

    render () {
        return (
            <div style={{display: 'none'}}>
                {this.props.action}
            </div>
        )
    }
}

const mapStateToProps = state => ({
    settingsUuid: state.settings.uuid
});

export default connect(mapStateToProps, {
    setLastAction
})(UsageAction);