import React, {Fragment} from 'react';
import GuitarsRightImage from "../../assets/images/right/right-guitars.png";
import RightImage from "../../components/elements/RightImage";
import Logo from "../../components/elements/Logo";
import SocialMenu from "../../components/elements/SocialMenu";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import Button from "../../components/elements/Button";
import actsJson from "../../json/acts";
import connect from "react-redux/src/connect/connect";
import ReactResizeDetector from 'react-resize-detector';
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import closeImg from "../../assets/images/closeo.svg";
import closeActiveImg from "../../assets/images/closeo_active.svg";
import comingSoonImg from "../../assets/images/lineup/coming_soon.jpg"
import YouTube from 'react-youtube-embed'
import UsageAction from "../../components/elements/Usage/UsageAction";
import PodcastLink from "../../components/elements/PodcastLink";

class Lineup extends React.Component {

    availableYears = [{year: 2022, acts: false}, {year: 2019, acts: actsJson}];

    state = {
        selectedYear: 2022,
        detailsAnimationDirection: 'none',
        leftKey: 0,
        acts: this.availableYears.find((el) => el.year === 2022).acts,
        selectedAct: null,
        nextSelectedAct: null,
        detailsHeight: {}
    };

    setYear = (year) => {
        this.setState({
            selectedYear: year.year
        });
        new Promise(() => {
            this.setState({
                acts: year.acts
            });
        })
    };

    handleDescriptionResize = (actKey, height) => {
        this.setState({
            detailsHeight: {
                ...this.state.detailsHeight,
                [actKey]: height
            }
        });
    };

    handleSelectDescription = (act) => {
        if (this.state.selectedAct) {
            if (this.state.selectedAct.name === act.name) {
                this.doClose(true);
                return false;
            }
            this.setState({
                nextSelectedAct: act
            });
            this.doClose(false);
            window.setTimeout(() => {
                this.doOpen(act);
            }, 600);
        } else {
            this.doOpen(act);
        }

    };

    doOpen = (act) => {
        this.setState({
            leftKey: this.state.acts.indexOf(act) % 3,
            selectedAct: act,
            nextSelectedAct: act
        });
        window.setTimeout(() => {
            this.setState({
                detailsAnimationDirection: 'in'
            });
        }, 50);
    };

    doClose = (isClose) => {
        this.setState({
            detailsAnimationDirection: 'out'
        });
        window.setTimeout(() => {
            this.setState({
                selectedAct: null,
                detailsAnimationDirection: 'none',
                nextSelectedAct: isClose ? null : this.state.nextSelectedAct
            })
        }, 580);
    };

    render() {
        const {acts, selectedAct, nextSelectedAct, detailsAnimationDirection, leftKey} = this.state;
        const {settings} = this.props;
        return (
            <div className="Lineup">
                <RightImage source={GuitarsRightImage}/>
                <div className="content-container">
                    <div className="content">
                        <Logo/>
                        <SocialMenu/>
                        <Footer/>
                        <Menu/>
                        <PodcastLink/>
                    </div>
                </div>
                <div className="left-text-column">
                    <HeadingWithLine lineColor="pink" textKey="lineup.title"/>
                    <div className="year-selector">
                        <div className="line-left line-color-pink"/>
                        {this.availableYears.map((year, yI) => (
                            <button onClick={() => this.setYear(year)} key={yI} className={`${year.year === this.state.selectedYear ? 'active' : ''}`}>{year.year}</button>
                        ))}
                    </div>
                    <div className="content-acts">
                        {acts ? (
                            <Fragment>
                                <div className="line-left line-color-pink"/>
                                <div className="text-1">
                                    <IntlHtmlMessages id="lineup.text1"/>
                                </div>
                                <div className="acts-container">
                                    {acts.map((act, aKey) => (
                                        <Fragment key={`aKey-${aKey}`}>
                                            <div
                                                className={`act-container${((selectedAct && selectedAct.name === act.name) || (nextSelectedAct && nextSelectedAct.name === act.name)) ? ' active' : ''}${(selectedAct && selectedAct.name === act.name) ? ' showBorders': ''}`}
                                            >
                                                <div className="toggle-description" onClick={() => this.handleSelectDescription(act)}/>
                                                <button className="close-container">
                                                    <img src={closeImg} alt="" className="default"/>
                                                    <img src={closeActiveImg} alt="" className="active"/>
                                                </button>
                                                <img src={require(`../../assets/images/lineup/${act.image1}`)} alt=""
                                                     className="act-image-1"/>
                                                <img src={require(`../../assets/images/lineup/${act.image2}`)} alt=""
                                                     className="act-image-2"/>
                                                <div className="title-container">
                                                    <h3 className="title" dangerouslySetInnerHTML={{__html: act.name}}/>
                                                    <div className="genre">
                                                        {act.genre}
                                                    </div>
                                                </div>
                                                <div className="border-left"/>
                                                <div className="border-top"/>
                                                <div className="border-right"/>
                                            </div>
                                            {((aKey + 1) % 3 === 0 || aKey === acts.length - 1) ? (
                                                <div
                                                    className={`act-details${selectedAct && acts.find(() => acts.indexOf(selectedAct) <= aKey && acts.indexOf(selectedAct) >= (aKey - (aKey % 3))) ? ' active' : ''} left-${leftKey} animationDirection-${detailsAnimationDirection}`}
                                                    style={{height: this.state.detailsHeight[aKey] ? this.state.detailsHeight[aKey] : 0, marginBottom: this.state.detailsHeight[aKey] ? '2px' : '0'}}>
                                                    <div className="act-content-container">
                                                        <ReactResizeDetector handleHeight
                                                                             onResize={(width, height) => this.handleDescriptionResize(aKey, height)}>
                                                            {selectedAct && acts.find(() => acts.indexOf(selectedAct) <= aKey && acts.indexOf(selectedAct) >= (aKey - (aKey % 3))) ? (
                                                                <div>
                                                                    <UsageAction action={`lineup-open-${selectedAct.name.replace('<br/>', '').replace('<br/>', '').replace(/[^a-zA-Z0-9]/g,'_')}`}/>
                                                                    <div className="hometown">
                                                                        {selectedAct.hometown}
                                                                    </div>
                                                                    <div className="name">
                                                                        {selectedAct.name.replace('<br/>', '').replace('<br/>', '')}
                                                                    </div>
                                                                    <div className="label">
                                                                        {selectedAct.label}
                                                                    </div>
                                                                    <div className="description">
                                                                        {settings.locale === 'de' ? (
                                                                            <p>
                                                                                {selectedAct.description_de}
                                                                            </p>
                                                                        ) : (
                                                                            <p>
                                                                                {selectedAct.description_en}
                                                                            </p>
                                                                        )}
                                                                    </div>
                                                                    <div className="audioEmbedded">
                                                                        {selectedAct.music.indexOf('soundcloud') !== -1 ? (
                                                                            <div className="soundcloud">
                                                                                <iframe width="100%" height="166" scrolling="no"
                                                                                        frameBorder="no" allow="autoplay"
                                                                                        src={`https://w.soundcloud.com/player/?url=${selectedAct.music}&color=%230019ff&auto_play=false&hide_related=false&show_comments=false&show_user=true&show_reposts=false&show_teaser=true`}/>
                                                                            </div>
                                                                        ) : selectedAct.music.indexOf('youtube') !== -1 ? (
                                                                            <div className="youtube">
                                                                                <YouTube id={selectedAct.music.substr(selectedAct.music.indexOf('h?v') + 4)}/>
                                                                            </div>
                                                                        ) : (
                                                                            <div className="other">
                                                                                <div dangerouslySetInnerHTML={{__html: selectedAct.music}}/>
                                                                            </div>
                                                                        )}
                                                                    </div>
                                                                </div>
                                                            ) : null}
                                                        </ReactResizeDetector>
                                                    </div>
                                                </div>
                                            ) : null}
                                        </Fragment>
                                    ))}
                                </div>
                                <div className="text-2">
                                    <IntlHtmlMessages id="lineup.text2"/>
                                </div>
                            </Fragment>
                        ) : (
                            <Fragment>
                                <div className="coming-soon">
                                    <div className="line-left line-color-pink"/>
                                    <img src={comingSoonImg} alt="coming soon"/>
                                    <div className="text-container">
                                        <h2>
                                            coming soon
                                        </h2>
                                        <a href="mailto:booking@entropiefestival.de">booking@entropiefestival.de</a>
                                    </div>
                                </div>
                            </Fragment>
                        )}
                    </div>
                    <div className="button-container">
                        <Button isLink={true} to="/tickets" titleKey="info.philosophy.buttons.tickets"/>
                        <Button isLink={true} to="/info" titleKey="info.philosophy.buttons.info"/>
                        <Button isLink={true} to="/supporter" titleKey="info.philosophy.buttons.supporter"/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

export default connect(mapStateToProps, {})(Lineup);
