/* eslint-disable jsx-a11y/href-no-hash */
class Config {
  static api() {
    return 'https://api-staging.entropiefestival.de/';
    // return 'https://et-api-staging.entropiefestival.com/';
  }

    static paypal() {
        return {
            env: 'sandbox'
        }
    }
}

export default Config;
