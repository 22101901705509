import React from 'react';
import connect from "react-redux/src/connect/connect";
import {injectIntl} from "react-intl";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import IntlMessages from "../../util/IntlMessages";

class TicketingStep7 extends React.Component {

    render() {
        const {ticketing} = this.props;
        return (
            <div className="TicketingStep7">
                <div className="title">
                    <IntlHtmlMessages id="ticketing.step7.title"/>
                </div>
                <div className="text">
                    <IntlHtmlMessages id="ticketing.step7.text"/>
                </div>
                <div className="paymentInfo">
                    <div className="paymentInfo-title">
                        <h4>
                            <IntlHtmlMessages id="ticketing.step7.paymentInfo.title"/>
                        </h4>
                        <div className="row row-1">
                            <b>
                                <IntlMessages id="ticketing.step7.paymentInfo.holder.title"/>
                            </b>
                            <IntlMessages id="ticketing.step7.paymentInfo.holder.value"/>
                        </div>
                        <div className="row row-2">
                            <b>
                                <IntlMessages id="ticketing.step7.paymentInfo.iban.title"/>
                            </b>
                            <IntlMessages id="ticketing.step7.paymentInfo.iban.value"/>
                        </div>
                        <div className="row row-3">
                            <b>
                                <IntlMessages id="ticketing.step7.paymentInfo.amount.title"/>
                            </b>
                            {ticketing.orderSummary.total.toLocaleString('de-DE', {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                                minimumIntegerDigits: 2
                            })} €
                        </div>
                        <div className="row row-4">
                            <b>
                                <IntlMessages id="ticketing.step7.paymentInfo.subject.title"/>
                            </b>
                            {ticketing.orderSummary.orderNumber} entropie 2021
                        </div>
                        <div className="row row-5">
                            <b>
                                <IntlMessages id="ticketing.step7.paymentInfo.bic.title"/>
                            </b>
                            <IntlMessages id="ticketing.step7.paymentInfo.bic.value"/>
                        </div>
                        <div className="row row-6">
                            <b>
                                <IntlMessages id="ticketing.step7.paymentInfo.bank.title"/>
                            </b>
                            <IntlMessages id="ticketing.step7.paymentInfo.bank.value"/>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {})(injectIntl(TicketingStep7));
