import React from 'react';

class PrivacyText extends React.Component {
    render() {
        return (
            <div className="PrivacyText">
                <div className={`line-left line-color-pink`}/>
                <p>Verantwortliche Stelle im Sinne der Datenschutzgesetze, insbesondere der
                    EU-Datenschutzgrundverordnung (DSGVO), ist:</p>
                <p/>
                <p>kulturevolution e.V.</p>
                <p>Großbeerenstraße 12</p>
                <p>10963 Berlin</p>
                <p/>
                <p><b>Ihre Betroffenenrechte</b></p>

                <p>Unter den angegebenen Kontaktdaten unseres Datenschutzbeauftragten können Sie jederzeit folgende
                    Rechte ausüben:</p>
                <p>Auskunft über Ihre bei uns gespeicherten Daten und deren Verarbeitung, Berichtigung unrichtiger
                    personenbezogener Daten,
                    Löschung Ihrer bei uns gespeicherten Daten, Einschränkung der Datenverarbeitung, sofern wir Ihre
                    Daten aufgrund gesetzlicher Pflichten noch nicht löschen dürfen, Widerspruch gegen die Verarbeitung
                    Ihrer Daten bei uns und
                    Datenübertragbarkeit, sofern Sie in die Datenverarbeitung eingewilligt haben oder einen Vertrag mit
                    uns abgeschlossen haben.</p>
                <p>Sofern Sie uns eine Einwilligung erteilt haben, können Sie diese jederzeit mit Wirkung für die
                    Zukunft widerrufen.</p>
                <p/>
                <p>
                    Sie können sich jederzeit mit einer Beschwerde an die für Sie zuständige Aufsichtsbehörde wenden.
                </p>
                <p>Ihre zuständige Aufsichtsbehörde richtet sich nach dem Bundesland Ihres Wohnsitzes, Ihrer Arbeit oder
                    der
                    mutmaßlichen Verletzung. Eine Liste der Aufsichtsbehörden (für den nichtöffentlichen Bereich) mit
                    Anschrift finden Sie unter:</p>
                <p>https://www.bfdi.bund.de/DE/Infothek/Anschriften_Links/anschriften_links-node.html.</p>
                <p/>
                <p><b>Zwecke der Datenverarbeitung durch die verantwortliche Stelle und Dritte</b></p>
                <p>
                    Wir verarbeiten Ihre personenbezogenen Daten nur zu den in dieser Datenschutzerklärung genannten
                    Zwecken. Eine Übermittlung Ihrer persönlichen Daten an Dritte zu anderen als den genannten Zwecken
                    findet nicht statt. Wir geben Ihre persönlichen Daten nur an Dritte weiter, wenn:</p>

                <p>Sie Ihre ausdrückliche Einwilligung dazu erteilt haben,<br/>
                    die Verarbeitung zur Abwicklung eines Vertrags mit Ihnen erforderlich ist,<br/>
                    die Verarbeitung zur Erfüllung einer rechtlichen Verpflichtung erforderlich ist,<br/>
                    die Verarbeitung zur Wahrung berechtigter Interessen erforderlich ist und kein Grund zur Annahme
                    besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an der Nichtweitergabe Ihrer Daten
                    haben.</p>
                <p><b>Löschung bzw. Sperrung der Daten</b></p>
                <p>
                    Wir halten uns an die Grundsätze der Datenvermeidung und Datensparsamkeit. Wir speichern Ihre
                    personenbezogenen Daten daher nur so lange, wie dies zur Erreichung der hier genannten Zwecke
                    erforderlich ist oder wie es die vom Gesetzgeber vorgesehenen vielfältigen Speicherfristen vorsehen.
                    Nach Fortfall des jeweiligen Zweckes bzw. Ablauf dieser Fristen werden die entsprechenden Daten
                    routinemäßig und entsprechend den gesetzlichen Vorschriften gesperrt oder gelöscht.
                </p>

                <p><b>Erfassung allgemeiner Informationen beim Besuch unserer Website</b></p>
                <p>
                    Wenn Sie auf unsere Website zugreifen, werden automatisch mittels eines Cookies Informationen
                    allgemeiner Natur erfasst. Diese Informationen (Server-Logfiles) beinhalten etwa die Art des
                    Webbrowsers, das verwendete Betriebssystem, den Domainnamen Ihres Internet-Service-Providers und
                    ähnliches. Hierbei handelt es sich ausschließlich um Informationen, welche keine Rückschlüsse auf
                    Ihre
                    Person zulassen.
                </p><p>
                Diese Informationen sind technisch notwendig, um von Ihnen angeforderte Inhalte von Webseiten
                korrekt
                auszuliefern und fallen bei Nutzung des Internets zwingend an. Sie werden insbesondere zu folgenden
                Zwecken verarbeitet:</p>

                <p>Sicherstellung eines problemlosen Verbindungsaufbaus der Website,<br/>
                    Sicherstellung einer reibungslosen Nutzung unserer Website,<br/>
                    Auswertung der Systemsicherheit und -stabilität sowie<br/>
                    zu weiteren administrativen Zwecken.</p>
                <p>Die Verarbeitung Ihrer personenbezogenen Daten basiert auf unserem berechtigten Interesse aus den
                    vorgenannten Zwecken zur Datenerhebung. Wir verwenden Ihre Daten nicht, um Rückschlüsse auf Ihre
                    Person
                    zu ziehen. Empfänger der Daten sind nur die verantwortliche Stelle und ggf. Auftragsverarbeiter.
                </p>
                <p>
                    Anonyme Informationen dieser Art werden von uns ggfs. statistisch ausgewertet, um unseren
                    Internetauftritt und die dahinterstehende Technik zu optimieren.</p>
                <p/>
                <p><b>Cookies</b></p>
                <p>Wie viele andere Webseiten verwenden wir auch so genannte „Cookies“. Cookies sind kleine
                    Textdateien,
                    die von einem Websiteserver auf Ihre Festplatte übertragen werden. Hierdurch erhalten wir
                    automatisch
                    bestimmte Daten wie z. B. IP-Adresse, verwendeter Browser, Betriebssystem und Ihre Verbindung
                    zum
                    Internet.</p>

                <p>Cookies können nicht verwendet werden, um Programme zu starten oder Viren auf einen Computer zu
                    übertragen. Anhand der in Cookies enthaltenen Informationen können wir Ihnen die Navigation
                    erleichtern
                    und die korrekte Anzeige unserer Webseiten ermöglichen.</p>

                <p>In keinem Fall werden die von uns erfassten Daten an Dritte weitergegeben oder ohne Ihre
                    Einwilligung
                    eine Verknüpfung mit personenbezogenen Daten hergestellt.</p>

                <p>Natürlich können Sie unsere Website grundsätzlich auch ohne Cookies betrachten. Internet-Browser
                    sind
                    regelmäßig so eingestellt, dass sie Cookies akzeptieren. Im Allgemeinen können Sie die
                    Verwendung
                    von
                    Cookies jederzeit über die Einstellungen Ihres Browsers deaktivieren. Bitte verwenden Sie die
                    Hilfefunktionen Ihres Internetbrowsers, um zu erfahren, wie Sie diese Einstellungen ändern
                    können.</p>
                <p>Bitte
                    beachten Sie, dass einzelne Funktionen unserer Website möglicherweise nicht funktionieren, wenn
                    Sie
                    die
                    Verwendung von Cookies deaktiviert haben.</p>
                <p/>
                <p><b>Verwendung von Google Ads Conversion-Tracking</b></p>
                <p>
                    Wir verwenden auf unserer Website das Online-Werbeprogramm „Google Ads“ und in diesem Rahmen
                    Conversion-Tracking (Besuchsaktionsauswertung). Das Google Conversion Tracking ist ein Analysedienst
                    der Google Inc. (1600 Amphitheatre Parkway, Mountain View, CA 94043, USA; „Google“). Wenn Sie auf
                    eine von Google geschaltete Anzeige klicken, wird ein Cookie für das Conversion-Tracking auf Ihrem
                    Rechner abgelegt. Diese Cookies haben eine begrenzte Gültigkeit, enthalten keine personenbezogenen
                    Daten und dienen somit nicht der persönlichen Identifizierung. Wenn Sie bestimmte Seiten unserer
                    Website besuchen und das Cookie noch nicht abgelaufen ist, können Google und wir erkennen, dass Sie
                    auf die Anzeige geklickt haben und zu dieser Seite weitergeleitet wurden. Jeder Google AdWords-Kunde
                    erhält ein anderes Cookie. Somit besteht keine Möglichkeit, dass Cookies über die Websites von
                    AdWords-Kunden nachverfolgt werden können.</p>
                <p>
                    Die Informationen, die mit Hilfe des Conversion-Cookie eingeholt werden, dienen dem Zweck
                    Conversion-Statistiken zu erstellen. Hierbei erfahren wir die Gesamtanzahl der Nutzer, die auf eine
                    unserer Anzeigen geklickt haben und zu einer mit einem Conversion-Tracking-Tag versehenen Seite
                    weitergeleitet wurden. Wir erhalten jedoch keine Informationen, mit denen sich Nutzer persönlich
                    identifizieren lassen. Die Verarbeitung erfolgt auf Grundlage des Art. 6 (1) lit. f DSGVO aus dem
                    berechtigten Interesse an zielgerichteter Werbung und der Analyse der Wirkung und Effizienz dieser
                    Werbung.</p>
                <p>
                    Sie haben das Recht aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit dieser
                    auf Art. 6 (1) f DSGVO beruhenden Verarbeitung Sie betreffender personenbezogener Daten zu
                    widersprechen.</p>
                <p>
                    Dazu können Sie die Speicherung der Cookies durch die Auswahl entsprechender technischer
                    Einstellungen Ihrer Browser-Software verhindern. Wir weisen Sie jedoch darauf hin, dass Sie in
                    diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen
                    können. Sie werden sodann nicht in die Conversion-Tracking Statistiken aufgenommen.
                    Des Weiteren können Sie in den Einstellungen für Werbung bei Google für Sie personalisierte Werbung
                    deaktivieren. Eine Anleitung dazu finden Sie unter <a
                    href="https://support.google.com/ads/answer/2662922?hl=de"
                    target="_blank">https://support.google.com/ads/answer/2662922?hl=de</a>. Darüber hinaus können Sie
                    die Verwendung von Cookies durch Drittanbieter deaktivieren, indem sie die Deaktivierungsseite der
                    Netzwerkwerbeinitiative (Network Advertising Initiative) unter <a
                    href="https://www.networkadvertising.org/choices/"
                    target="_blank">https://www.networkadvertising.org/choices/</a> aufrufen und die dort genannten
                    weiterführenden Information zum Opt-Out umsetzen.
                </p>
                <p>
                    Weiterführende Informationen sowie die Datenschutzerklärung von Google finden Sie unter: <a
                    href="https://www.google.de/policies/privacy/"
                    target="_blank">https://www.google.de/policies/privacy/</a>
                </p>
                <p><b>Registrierung auf unserer Webseite</b></p>

                <p>Bei der Registrierung für die Nutzung unserer personalisierten Leistungen werden einige
                    personenbezogene
                    Daten erhoben, wie Name, Anschrift, Kontakt- und Kommunikationsdaten wie Telefonnummer und
                    E-Mail-Adresse. Sind Sie bei uns registriert, können Sie auf Inhalte und Leistungen zugreifen,
                    die
                    wir
                    nur registrierten Nutzern anbieten. Angemeldete Nutzer haben zudem die Möglichkeit, bei Bedarf
                    die
                    bei
                    Registrierung angegebenen Daten jederzeit zu ändern oder zu löschen. Selbstverständlich erteilen
                    wir
                    Ihnen darüber hinaus jederzeit Auskunft über die von uns über Sie gespeicherten
                    personenbezogenen
                    Daten.</p>
                <p>Gerne berichtigen bzw. löschen wir diese auch auf Ihren Wunsch, soweit keine gesetzlichen
                    Aufbewahrungspflichten entgegenstehen. Zur Kontaktaufnahme in diesem Zusammenhang nutzen Sie
                    bitte
                    die
                    am Ende dieser Datenschutzerklärung angegebenen Kontaktdaten.</p>
                <p/>
                <p><b>Erbringung kostenpflichtiger Leistungen</b></p>

                <p>Zur Erbringung kostenpflichtiger Leistungen werden von uns zusätzliche Daten erfragt, wie z.B.
                    Zahlungsangaben, um Ihre Bestellung ausführen zu können. Wir speichern diese Daten in unseren
                    Systemen
                    bis die gesetzlichen Aufbewahrungsfristen abgelaufen sind.</p>

                <p><b>SSL-Verschlüsselung</b></p>

                <p>Um die Sicherheit Ihrer Daten bei der Übertragung zu schützen, verwenden wir dem aktuellen Stand
                    der
                    Technik entsprechende Verschlüsselungsverfahren (z. B. SSL) über HTTPS.</p>
                <p/>
                <p><b>Newsletter</b></p>

                <p>Auf Grundlage Ihrer ausdrücklich erteilten Einwilligung, übersenden wir Ihnen regelmäßig unseren
                    Newsletter bzw. vergleichbare Informationen per E-Mail an Ihre angegebene E-Mail-Adresse.</p>

                <p>Für den Empfang des Newsletters ist die Angabe Ihrer E-Mail-Adresse ausreichend. Bei der
                    Anmeldung
                    zum
                    Bezug unseres Newsletters werden die von Ihnen angegebenen Daten ausschließlich für diesen Zweck
                    verwendet. Abonnenten können auch über Umstände per E-Mail informiert werden, die für den Dienst
                    oder
                    die Registrierung relevant sind (Beispielsweise Änderungen des Newsletterangebots oder
                    technische
                    Gegebenheiten).</p>
                <p/>
                <p>
                    Für eine wirksame Registrierung benötigen wir eine valide E-Mail-Adresse. Um zu überprüfen, dass
                    eine
                    Anmeldung tatsächlich durch den Inhaber einer E-Mail-Adresse erfolgt, setzen wir das
                    „Double-opt-in“-Verfahren ein. Hierzu protokollieren wir die Bestellung des Newsletters, den
                    Versand
                    einer Bestätigungsmail und den Eingang der hiermit angeforderten Antwort. Weitere Daten werden
                    nicht
                    erhoben. Die Daten werden ausschließlich für den Newsletterversand verwendet und nicht an Dritte
                    weitergegeben.</p>
                <p>
                    Die Einwilligung zur Speicherung Ihrer persönlichen Daten und ihrer Nutzung für den
                    Newsletterversand
                    können Sie jederzeit widerrufen. In jedem Newsletter findet sich dazu ein entsprechender Link.
                    Außerdem
                    können Sie sich jederzeit auch direkt auf dieser Webseite abmelden oder uns Ihren entsprechenden
                    Wunsch
                    über die am Ende dieser Datenschutzhinweise angegebene Kontaktmöglichkeit mitteilen.</p>
                <p/>
                <p><b>Kontaktformular</b></p>

                <p>Treten Sie bzgl. Fragen jeglicher Art per E-Mail oder Kontaktformular mit uns in Kontakt,
                    erteilen
                    Sie
                    uns zum Zwecke der Kontaktaufnahme Ihre freiwillige Einwilligung. Hierfür ist die Angabe einer
                    validen
                    E-Mail-Adresse erforderlich. Diese dient der Zuordnung der Anfrage und der anschließenden
                    Beantwortung
                    derselben. Die Angabe weiterer Daten ist optional. Die von Ihnen gemachten Angaben werden zum
                    Zwecke
                    der
                    Bearbeitung der Anfrage sowie für mögliche Anschlussfragen gespeichert. Nach Erledigung der von
                    Ihnen
                    gestellten Anfrage werden personenbezogene Daten automatisch gelöscht.</p>
                <p/>
                <p><b>Verwendung von Scriptbibliotheken (Google Webfonts)</b></p>

                <p>Um unsere Inhalte browserübergreifend korrekt und grafisch ansprechend darzustellen, verwenden
                    wir
                    auf
                    dieser Website Scriptbibliotheken und Schriftbibliotheken wie z. B. Google Webfonts
                    (https://www.google.com/webfonts/). Google Webfonts werden zur Vermeidung mehrfachen Ladens in
                    den
                    Cache
                    Ihres Browsers übertragen. Falls der Browser die Google Webfonts nicht unterstützt oder den
                    Zugriff
                    unterbindet, werden Inhalte in einer Standardschrift angezeigt.</p>

                <p>Der Aufruf von Scriptbibliotheken oder Schriftbibliotheken löst automatisch eine Verbindung zum
                    Betreiber der Bibliothek aus. Dabei ist es theoretisch möglich – aktuell allerdings auch unklar
                    ob
                    und
                    ggf. zu welchen Zwecken – dass Betreiber entsprechender Bibliotheken Daten erheben.</p>
                <p/>
                <p><b>Verwendung von Adobe Typekit</b></p>

                <p>Wir setzen Adobe Typekit zur visuellen Gestaltung unserer Website ein. Typekit ist ein Dienst
                    der
                    Adobe
                    Systems Software Ireland Ltd. der uns den Zugriff auf eine Schriftartenbibliothek gewährt. Zur
                    Einbindung der von uns benutzten Schriftarten, muss Ihr Browser eine Verbindung zu einem Server
                    von
                    Adobe in den USA aufbauen und die für unsere Website benötigte Schriftart herunterladen. Adobe
                    erhält
                    hierdurch die Information, dass von Ihrer IP-Adresse unsere Website aufgerufen wurde. Weitere
                    Informationen zu Adobe Typekit finden Sie in den Datenschutzhinweisen von Adobe, die Sie hier
                    abrufen
                    können: www.adobe.com/privacy/typekit.html</p>
                <p/>

                <p><b>Änderung unserer Datenschutzbestimmungen</b></p>

                <p>Wir weisen ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der
                    Kommunikation
                    per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff durch Dritte
                    geschützt
                    werden kann.</p>

                <p>Die Verwendung der Kontaktdaten unseres Impressums zur gewerblichen Werbung ist ausdrücklich
                    nicht
                    erwünscht, es sei denn wir hatten zuvor unsere schriftliche Einwilligung erteilt oder es besteht
                    bereits
                    eine Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten Personen
                    widersprechen
                    hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten.</p>
                <p/>
                <p><b>Personenbezogene Daten</b></p>

                <p>Sie können unsere Webseite ohne Angabe personenbezogener Daten besuchen. Soweit auf unseren
                    Seiten
                    personenbezogene Daten (wie Name, Anschrift oder E-Mail Adresse) erhoben werden, erfolgt dies,
                    soweit
                    möglich, auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an
                    Dritte
                    weitergegeben. Sofern zwischen Ihnen und uns ein Vertragsverhältnis begründet, inhaltlich
                    ausgestaltet
                    oder geändert werden soll oder Sie an uns eine Anfrage stellen, erheben und verwenden wir
                    personenbezogene Daten von Ihnen, soweit dies zu diesen Zwecken erforderlich ist
                    (Bestandsdaten).</p>
                <p>Wir
                    erheben, verarbeiten und nutzen personenbezogene Daten soweit dies erforderlich ist, um Ihnen
                    die
                    Inanspruchnahme des Webangebots zu ermöglichen (Nutzungsdaten). Sämtliche personenbezogenen
                    Daten
                    werden
                    nur solange gespeichert wie dies für den genannten Zweck (Bearbeitung Ihrer Anfrage oder
                    Abwicklung
                    eines Vertrags) erforderlich ist. Hierbei werden steuer- und handelsrechtliche
                    Aufbewahrungsfristen
                    berücksichtigt. Auf Anordnung der zuständigen Stellen dürfen wir im Einzelfall Auskunft über
                    diese
                    Daten
                    (Bestandsdaten)erteilen, soweit dies für Zwecke der Strafverfolgung, zur Gefahrenabwehr, zur
                    Erfüllung
                    der gesetzlichen Aufgaben der Verfassungsschutzbehörden oder des Militärischen Abschirmdienstes
                    oder
                    zur Durchsetzung der Rechte am geistigen Eigentum erforderlich ist.</p>
                <p/>
                <p><b>Auskunftsrecht</b></p>

                <p>Sie haben das jederzeitige Recht, sich unentgeltlich und unverzüglich über die zu Ihrer Person
                    erhobenen
                    Daten zu erkundigen. Sie haben das jederzeitige Recht, Ihre Zustimmung zur Verwendung Ihrer
                    angegeben
                    persönlichen Daten mit Wirkung für die Zukunft zu widerrufen. Zur Auskunftserteilung wenden Sie
                    sich
                    bitte an den Anbieter unter den Kontaktdaten im Impressum.</p>

                <p>Wir behalten uns vor, diese Datenschutzerklärung anzupassen, damit sie stets den aktuellen
                    rechtlichen
                    Anforderungen entspricht oder um Änderungen unserer Leistungen in der Datenschutzerklärung
                    umzusetzen,
                    z.B. bei der Einführung neuer Services. Für Ihren erneuten Besuch gilt dann die neue
                    Datenschutzerklärung.</p>

                <p><b>Fragen an den Datenschutzbeauftragten</b></p>

                <p>Wenn Sie Fragen zum Datenschutz haben, schreiben Sie uns bitte eine E-Mail oder wenden Sie sich
                    direkt
                    an die für den Datenschutz verantwortliche Person in unserer Organisation:</p>

                <p>Aenias Fritsch<br/>
                    aenias@entropiefestival.de<br/>
                    Telefon: 015253754598</p>
            </div>
        )
    }
}

export default PrivacyText;