import React from 'react';
import closeIcon from '../../assets/images/close.svg';
import closeIconActive from '../../assets/images/close_active.svg';
import IntlHtmlMessages from "../../util/IntlHtmlMessages";

class CookieBanner extends React.Component {
    state = {
        cookiesAccepted: false
    };

    componentWillMount() {
        this.setState({
            cookiesAccepted: window.localStorage.getItem('cookiesAccepted') && window.localStorage.getItem('cookiesAccepted') === 'true'
        });
    }

    handleAcceptCookies = () => {
        window.localStorage.setItem('cookiesAccepted', 'true');
        this.setState({
            cookiesAccepted: true
        })
    };

    render() {
        return (
            !this.state.cookiesAccepted ? (
                <div className="CookieBanner objektiv-mk3-regular" onClick={this.handleAcceptCookies}>
                    <button onClick={this.handleAcceptCookies} className="close">
                        <img src={closeIcon} className="default" alt=""/>
                        <img src={closeIconActive} className="active" alt=""/>
                    </button>
                    <IntlHtmlMessages id="cookieBanner.text"/>
                </div>
            ) : null
        )
    }
}

export default CookieBanner;