import React from 'react';
import IntlMessages from "../../util/IntlMessages";
import Button from "../../components/elements/Button";
import NewsletterApi from "../../apis/Newsletter";
import { injectIntl } from 'react-intl';

class NewsletterEntry extends React.Component {

    state = {
        isLoading: false,
        email: '',
        hadSuccess: false,
        errors: {}
    };

    submitNewsletterForm = () => {
        this.setState({isLoading: true});
        NewsletterApi.createEntry(this.state.email)
            .then((response) => {
                if (!!response.data.success) {
                    this.setState({
                        isLoading: false,
                        email: '',
                        hadSuccess: true,
                        errors: {}
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        errors: response.data.errors
                    });
                }
            });
    };

    render() {
        const { intl } = this.props;
        return (
            <div className="NewsletterEntry">
                {this.state.isLoading ? (
                    <div className="loading">
                        Loading...
                    </div>
                ) : (
                    this.state.hadSuccess ? (
                        <div className="done">
                            <IntlMessages id="info.contact.newsletter.success"/>
                        </div>
                    ) : (
                        <div className="form">
                            <input className="input-field" type="text" value={this.state.email}
                                   onChange={(e) => this.setState({email: e.target.value})}
                                   placeholder={intl.formatMessage({id: "info.contact.newsletter.form.email.label"})}/>
                            {this.state.errors && this.state.errors.email ? (
                                <div className="form-error">
                                    <IntlMessages
                                        id={`info.contact.newsletter.form.email.error.${this.state.errors.email}`}/>
                                </div>
                            ) : null}
                            <Button onClick={this.submitNewsletterForm} titleKey="info.contact.newsletter.form.submit"/>
                        </div>
                    )
                )}
            </div>
        )
    }
}

export default injectIntl(NewsletterEntry);