import React, {Fragment} from 'react';
import HeadingWithLine from "../../components/elements/content/HeadingWithLine";
import {injectIntl} from 'react-intl';
import IntlMessages from "../../util/IntlMessages";
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import Button from "../../components/elements/Button";
import TextBlockWithLine from "../../components/elements/content/TextBlockWithLine";
import SupporterApi from "../../apis/Supporter";
import connect from "react-redux/src/connect/connect";
import {hideLoader, showLoader} from "../../actions";

class SupporterRegister extends React.Component {

    state = {
        isLoading: false,
        success: false,
        firstname: '',
        lastname: '',
        email: '',
        phone: '',
        password: '',
        password2: '',
        errors: {}
    };

    handleFormSubmit = (e) => {
        e.preventDefault();
        this.setState({isLoading: true});
        this.props.showLoader();
        SupporterApi.register(this.state.firstname, this.state.lastname, this.state.phone, this.state.email, this.state.password, this.state.password2)
            .then((response) => {
                this.props.hideLoader();
                if (response.data.success) {
                    this.setState({
                        isLoading: false,
                        success: true,
                        firstname: '',
                        lastname: '',
                        email: '',
                        phone: '',
                        password: '',
                        password2: '',
                        errors: {}
                    });
                } else {
                    this.setState({
                        isLoading: false,
                        success: false,
                        errors: response.data.errors
                    });
                }
            })
    };

    render() {
        const {intl} = this.props;
        return (
            <div className="left-text-column">
                <HeadingWithLine lineColor="pink" textKey="supporter.register.title"/>
                {this.state.success ? (
                    <div className="success">
                        <IntlHtmlMessages id="supporter.register.success"/>
                    </div>
                ) : (
                    <Fragment>
                        <div className="form form-with-line">
                            <div className={`line-left line-color-pink`}/>
                            <form onSubmit={this.handleFormSubmit}>
                                <div className="form-group">
                                    <input className="input-field" type="text" value={this.state.firstname}
                                           onChange={(e) => this.setState({firstname: e.target.value})}
                                           placeholder={intl.formatMessage({id: "supporter.register.firstname.label"})}/>
                                    {this.state.errors.firstname ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`supporter.register.firstname.error.${this.state.errors.firstname}`}/>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <input className="input-field" type="text" value={this.state.lastname}
                                           onChange={(e) => this.setState({lastname: e.target.value})}
                                           placeholder={intl.formatMessage({id: "supporter.register.lastname.label"})}/>
                                    {this.state.errors.lastname ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`supporter.register.lastname.error.${this.state.errors.lastname}`}/>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <input className="input-field" type="text" value={this.state.phone}
                                           onChange={(e) => this.setState({phone: e.target.value})}
                                           placeholder={intl.formatMessage({id: "supporter.register.phone.label"})}/>
                                    {this.state.errors.phone ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`supporter.register.phone.error.${this.state.errors.phone}`}/>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <input className="input-field" type="text" value={this.state.email}
                                           onChange={(e) => this.setState({email: e.target.value})}
                                           placeholder={intl.formatMessage({id: "supporter.register.email.label"})}/>
                                    {this.state.errors.email ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`supporter.register.email.error.${this.state.errors.email}`}/>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <input className="input-field" type="password" value={this.state.password}
                                           onChange={(e) => this.setState({password: e.target.value})}
                                           placeholder={intl.formatMessage({id: "supporter.register.password.label"})}/>
                                    {this.state.errors.password ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`supporter.register.password.error.${this.state.errors.password}`}/>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-group">
                                    <input className="input-field" type="password" value={this.state.password2}
                                           onChange={(e) => this.setState({password2: e.target.value})}
                                           placeholder={intl.formatMessage({id: "supporter.register.password2.label"})}/>
                                    {this.state.errors.password2 ? (
                                        <div className="form-error">
                                            <IntlMessages
                                                id={`supporter.register.password2.error.${this.state.errors.password2}`}/>
                                        </div>
                                    ) : null}
                                </div>
                                <div className="form-submit">
                                    <Button onClick={this.handleFormSubmit} titleKey="supporter.register.submit"/>
                                </div>
                            </form>
                        </div>
                        <div className="TextBlockWithLine-register">
                            <TextBlockWithLine lineColor="pink" textKey="supporter.register.text"/>
                        </div>
                        <div className="supporter-register-back-button-container">
                            <Button isLink={true} to="/supporter" titleKey="supporter.register.backToLogin"/>
                        </div>
                    </Fragment>
                )}
            </div>
        )
    }
}

export default connect(null, {
    showLoader, hideLoader
})(injectIntl(SupporterRegister));