import {SUPPORTER_LOGOUT, SUPPORTER_SET_AUTHENTICATION} from "../config/actions";

export const supporterSetAuthentication = (payload) => {
    return {
        type: SUPPORTER_SET_AUTHENTICATION,
        payload
    }
};

export const supporterLogout = () => {
    return {
        type: SUPPORTER_LOGOUT
    }
};