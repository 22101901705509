import React from 'react';
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import connect from "react-redux/src/connect/connect";
import {backToStep2} from "../../actions";
import {injectIntl} from "react-intl";
import Button from "../../components/elements/Button";

class TicketingStep6 extends React.Component {
    render() {
        return (
            <div className="TicketingStep6">
                <div className="paypalerror-container">
                    <div className="line-left line-color-pink"/>
                    <div className="title">
                        <IntlHtmlMessages id="ticketing.step6.title"/>
                    </div>
                    <div className="text text-1">
                        <IntlHtmlMessages id="ticketing.step6.text"/>
                    </div>
                    <div className="buttons">
                        <Button onClick={this.props.backToStep2} titleKey="ticketing.step6.buttons.again"/>
                        <Button isLink={true} to="/info/contact" titleKey="ticketing.step6.buttons.contact"/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {
    backToStep2
})(injectIntl(TicketingStep6));