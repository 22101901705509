import React from 'react';
import IntlHtmlMessages from "../../util/IntlHtmlMessages";
import {injectIntl} from "react-intl";
import Button from "../../components/elements/Button";
import LoginApi from '../../apis/Login';
import connect from "react-redux/src/connect/connect";
import {hideLoader, setBearerToken, showLoader} from "../../actions";
import {withRouter} from "react-router";
import closeImage from "../../assets/images/close.svg";

class LoginAuthentication extends React.Component {

    state = {
        email: '',
        code: '',
        error: false
    };

    authenticate = (e) => {
        e.preventDefault();
        this.setState({error: false});
        this.props.showLoader();
        LoginApi.authenticate(this.state.email, this.state.code)
            .then((response) => {
                this.props.hideLoader();
                if(response.status === 200 && response.data.roles.includes('ROLE_ORDER')) {
                    this.props.setBearerToken(response.data.access_token);
                    this.props.history.push('/login/overview');
                } else {
                    this.setState({
                        error: true
                    });
                }
            })
            .catch((error) => {
                this.props.hideLoader();
                this.setState({
                    error: true
                });
            })
    };

    render() {
        const {intl} = this.props;
        return (
            <div className="LoginAuthentication">
                {this.state.showOfflineTicketInfo ? (
                    <div className="offlineTicketInfo">
                        <button className="closeButton" onClick={() => this.setState({showOfflineTicketInfo: false})}>
                            <img src={closeImage} alt=""/>
                        </button>
                        <div className="text-container" id="offlineTicketInfoText">
                            <IntlHtmlMessages id="login.offlineTicketInfo.text"/>
                        </div>
                    </div>
                ) : null}
                <div className="title">
                    <div className="line-left line-color-pink"/>
                    <IntlHtmlMessages id="login.authentication.title"/>
                </div>
                <div className="form-container">
                    <form onSubmit={this.authenticate}>
                        <div className="form-group">
                            <input className="input-field" type="text" value={this.state.email}
                                   onChange={(e) => this.setState({email: e.target.value})}
                                   placeholder={intl.formatMessage({id: "login.authentication.email.label"})}/>
                        </div>
                        <div className="form-group">
                            <input className="input-field" type="text" value={this.state.code}
                                   onChange={(e) => this.setState({code: e.target.value})}
                                   placeholder={intl.formatMessage({id: "login.authentication.code.label"})}/>
                        </div>
                    </form>
                    <Button onClick={this.authenticate} titleKey={"login.authentication.submit"}/>
                    {this.state.error ? (
                        <div className="error">
                            <IntlHtmlMessages id="login.authentication.error"/>
                        </div>
                    ) : null}
                    <Button onClick={() => this.setState({showOfflineTicketInfo: true})}
                            titleKey={"login.authentication.offlineInfoButton"}/>
                </div>
            </div>
        )
    }
}

export default connect(null, {
    showLoader, hideLoader, setBearerToken
})(injectIntl(withRouter(LoginAuthentication)));
