import axios from 'axios';
import Config from '../config/Config';
import {store} from '../store';
import {setLastAction} from "../actions";

class Usage {
    static sendPageLoad(uuid) {
        store.dispatch(setLastAction());
        return axios.post(`${Config.api()}usage`, {
            usageLogEntryType: 'PAGE_LOAD',
            uuid: uuid,
            referrer: document.referrer,
            path: document.location.pathname
        })
    }
    static sendNavigation(uuid, path, referrer) {
        store.dispatch(setLastAction());
        return axios.post(`${Config.api()}usage`, {
            usageLogEntryType: 'NAVIGATION',
            uuid: uuid,
            referrer: referrer,
            path: path
        })
    }
    static sendAction(uuid, action, referrer) {
        store.dispatch(setLastAction());
        return axios.post(`${Config.api()}usage`, {
            usageLogEntryType: 'ACTION',
            uuid: uuid,
            path: window.location.pathname,
            action: action,
            referrer: referrer
        })
    }
}

export default Usage;