import React from 'react';
import Logo from "../../components/elements/Logo";
import Footer from "../../components/elements/Footer/Footer";
import Menu from "../../components/elements/Menu/Menu";
import SocialMenu from "../../components/elements/SocialMenu";
import HomeShortLinks from "./HomeShortLinks";
import backgroundImage from "../../assets/images/home/background.gif";
import image2022 from "../../assets/images/home/2022.png";
import PodcastLink from "../../components/elements/PodcastLink";
import Button from "../../components/elements/Button";
import connect from "react-redux/src/connect/connect";
import {showPopup2022} from "../../actions/Popup2022";

class Home extends React.Component {
    render() {
        return (
            <div className="Home">
                <div className="backgrounds" style={{ backgroundImage: `url(${backgroundImage})`}}/>
                <div className="content-container">
                    <div className="content">
                        <Logo showFullLogo={true}/>
                        <Footer/>
                        <Menu/>
                        <SocialMenu whiteMode/>
                        <div className="title">
                            <img src={image2022} alt=""/>
                            <Button onClick={this.props.showPopup2022} titleKey="home.moreInformation"/>
                        </div>
                        <HomeShortLinks />
                        <PodcastLink/>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(null, {
    showPopup2022
})(Home);
