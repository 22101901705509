import React from 'react';

class AgbText extends React.Component {
    render() {
        return (
            <div className="AgbText">
                <div className={`line-left line-color-pink`}/>
                <p>
                    Allgemeine Geschäftsbedingungen des Ticketverkaufs für www.entropiefestival.de vom Verein
                    kulturevolution e.V.
                </p>
                <p/>
                <p><b>§1 Geltung gegenüber Unternehmern und Begriffsdefinitionen</b></p>
                <p>
                    (1) Die nachfolgenden Allgemeinen Geschäftbedingungen gelten für alle Lieferungen zwischen
                    kulturevolution e.V., Großbeerenstraße 12, 10963 Berlin und einem Verbraucher in ihrer zum Zeitpunkt
                    der Bestellung gültigen Fassung.
                </p>
                <p>
                    (2) „Verbraucher“ im Sinne dieser Geschäftsbedingungen ist jede natürliche Person, die ein
                    Rechtsgeschäft zu einem Zwecke abschließt, der weder ihrer gewerblichen, noch ihrer selbständigen
                    beruflichen Tätigkeit zugerechnet werden kann.
                </p>
                <p/>

                <p><b>§2 Zustandekommen eines Vertrages, Speicherung des Vertragstextes</b></p>

                <p>(1) Die folgenden Regelungen über den Vertragsabschluss gelten für Bestellungen über unseren
                    Internetshop http://www.entropiefestival.de.</p>
                <p>(2) Im Falle des Vertragsschlusses kommt der Vertrag mit kulturevolution e.V., Großbeerenstraße 12,
                    10963 Berlin, zustande.</p>
                <p>(3) Mit der Bestellung der gewünschten Ware gibt der Verbraucher ein für ihn verbindliches Angebot
                    auf Abschluss eines Kaufvertrages ab.</p>
                <p>(4) Bei Eingang einer Bestellung in unserem Internetshop gelten folgende Regelungen: Der Verbraucher
                    gibt ein bindendes Vertragsangebot ab, indem er die in unserem Internetshop vorgesehene
                    Bestellprozedur erfolgreich durchläuft.</p>
                <p>Es ist nur erlaubt, Tickets zum eigenen Gebrauch (auch für Freunde) zu erwerben. Der Kauf von Tickets
                    mit dem Ziel des Weiterverkauf ist ausdrücklich NICHT erlaubt.</p>
                <p>Gewerbsmäßiger Weiterverkauf von Tickets ist generell untersagt!</p>
                <p>Der Ticket-Käufer ist selbst dafür verantwortlich sein Ticket vor dem Zugriff fremder Personen in
                    seinem eMail-Account zu schützen und bis zur Veranstaltung in Papierform oder digital
                    aufzubewahren.</p>
                <p>Bei Verdacht auf Handlungen mit betrügerischer Absicht oder gewerbsmäßigen Weiterverkauf ist der
                    Veranstalter berechtigt, das Ticket zu stornieren. Der ursprüngliche Käufer hat, sofern er nicht
                    Teil der betrügerischen Handlung war, ggf. Anspruch auf Schadenersatz in Höhe des Ticketpreises. Für
                    die Korrrektheit der Angaben beim Ticketkauf ist der/die Käufer/in verantwortlich. Bei falschen
                    Angaben insbesondere bei der Altersangabe kann das Ticket ungültig werden. Ein Anspruch auf
                    Rückerstattung bei falschen Angaben besteht nicht.</p>
                <p>Die Bestellung erfolgt in folgenden Schritten:</p>
                <p>1) Auswahl der gewünschten Tickets und Durchlaufen des Bestellprozesses</p>
                <p>2) Bestätigen durch Anklicken des Buttons „Liebe kaufen (:“</p>
                <p>3) Ggf. Erhalt der Reservierungsbestätigung an die angegebene Emailadresse</p>
                <p>4) Zahlung durch Nutzung angegebener Zahlungsmethode</p>
                <p>5) Versand von PDF(s) an die angegebene Emailadresse oder Hardtickets(s) an die angegebene
                    Postadresse.</p>
                <p>Der Verbraucher kann vor dem verbindlichen Absenden der Bestellung durch Betätigen des
                    „zurück“-Buttons
                    nach Kontrolle seiner Angaben wieder zu der Internetseite gelangen, auf der die Angaben des Kunden
                    erfasst werden und Eingabefehler berichtigen bzw. durch Schließen des Internetbrowsers den
                    Bestellvorgang abbrechen. Wir bestätigen den Eingang der Bestellung unmittelbar durch eine
                    automatisch
                    generierte E-Mail. Mit dieser nehmen wir Ihr Angebot an.</p>
                <p>(5) Speicherung des Vertragstextes bei Bestellungen über unseren Internetshop Wir senden Ihnen die
                    Bestelldaten und einen Link zu unserer AGB per E-Mail zu. Die AGB können Sie jederzeit auch online
                    einsehen unter entropiefestival.de/agb. Ihre Bestelldaten sind aus Sicherheitsgründen nicht mehr
                    über
                    das Internet zugänglich.</p>
                <p/>
                <p><b>§3 Preise, Versandkosten, Zahlung, Fälligkeit</b></p>
                <p>(1) Die angegebenen Preise enthalten die gesetzliche Umsatzsteuer und sonstige Preisbestandteile.
                    Hinzu
                    kommen etwaige Versandkosten.</p>
                <p>(2) Der Verbraucher hat die Möglichkeit der Zahlung per Vorkasse, PayPal oder Lastschrift.</p>
                <p>(3) Hat der Verbraucher die Zahlung per Vorkasse gewählt, so verpflichtet er sich, den Kaufpreis
                    unverzüglich nach Vertragsschluss zu zahlen.</p>
                <p/>
                <p><b>§4 Lieferung</b></p>
                <p>(1) Einige der angegebenen Artikel sind unter Umständen nicht sofort versandfertig, und werden ggf.
                    zu
                    einem späteren Zeitpunkt an den Verbraucher versendet.</p>
                <p>(2) Hat der Verbraucher die Zahlung per Vorkasse gewählt, so versenden wir die Ware nicht vor
                    Zahlungseingang.</p>
                <p/>
                <p><b>§5 Eigentumsvorbehalt</b></p>
                <p>Wir behalten uns das Eigentum an der Ware bis zur vollständigen Bezahlung des Kaufpreises vor.</p>
                <p/>
                <p><b>§6 Widerrufsrecht, Widerrufsbelehrung</b></p>
                <p>Sie können Ihre Vertragserklärung innerhalb von 14 Tagen ohne Angabe von Gründen in Textform (Brief,
                    E-Mail) widerrufen. Die Frist beginnt nach Erhalt dieser Belehrung in Textform, jedoch nicht vor
                    Eingang
                    der Ware beim Empfänger (bei der wiederkehrenden Lieferung gleichartiger Waren nicht vor Eingang der
                    ersten Teillieferung) und auch nicht vor Erfüllung unserer Informationspflichten gemäß Artikel 246 §
                    2
                    in Verbindung mit § 1 Abs. 1 und 2 EGBGB sowie unserer Pflichten gemäß § 312e Abs. 1 Satz 1 BGB in
                    Verbindung mit Artikel 246 § 3 EGBGB. Zur Wahrung der Widerrufsfrist genügt die rechtzeitige
                    Absendung
                    des Widerrufs oder der Sache.</p>
                <p>Für Tickets gibt es kein Widerrufs- und Rückgaberecht! Für Dienstleistungen aus dem Bereich der
                    Freizeitveranstaltungen, insbesondere Eintrittskarten für Veranstaltungen, findet das
                    Fernabsatzgesetz
                    gemäß §312g BGB keine Anwendung. Dies beinhaltet, dass ein zweiwöchiges Widerrufsrecht
                    ausgeschlossen
                    ist. Jede Bestellung von Tickets ist bindend und verpflichtet zur Abnahme und Bezahlung der
                    bestellten
                    Tickets.</p>
                <p/>
                <p>Der Widerruf ist zu richten an:</p>
                <p>kulturevolution e.V.</p>
                <p>Volkram Weber</p>
                <p>Großbeerenstraße 12</p>
                <p>10963 Berlin</p>
                <p>E-Mail: widerruf@entropiefestival.de</p>
                <p/>
                <p>Im Falle eines wirksamen Widerrufs sind die beiderseits empfangenen Leistungen zurückzugewähren und
                    ggf.
                    gezogene Nutzungen (z. B. Zinsen) herauszugeben. Können Sie uns die empfangene Leistung ganz oder
                    teilweise nicht oder nur in verschlechtertem Zustand zurückgewähren, müssen Sie uns insoweit ggf.
                    Wertersatz leisten. Verpflichtungen zur Erstattung von Zahlungen müssen innerhalb von 30 Tagen
                    erfüllt
                    werden. Die Frist beginnt für Sie mit der Absendung Ihrer Widerrufserklärung oder der Sache, für uns
                    mit
                    deren Empfang.</p>
                <p>Bei einer Dienstleistung erlischt Ihr Widerrufsrecht vorzeitig, wenn Ihr Vertragspartner mit der
                    Ausführung der Dienstleistung mit Ihrer ausdrücklichen Zustimmung vor Ende der Widerrufsfrist
                    begonnen
                    hat oder Sie diese selbst veranlasst haben. Ende der Widerrufsbelehrung.</p>
                <p/>
                <p><b>§7 Vertragliche Regelung bezüglich der Rücksendekosten bei Widerruf</b></p>
                <p>Sollten Sie von Ihrem Widerrufsrecht Gebrauch machen, so gilt im Einklang mit § 357 Abs. 2 BGB,
                    folgende
                    Vereinbarung, nach der Sie die regelmäßigen Kosten der Rücksendung zu tragen haben, wenn die
                    gelieferte
                    Ware der bestellten entspricht.</p>
                <p/>
                <p><b>§8 Teilnahme am entropie Festival</b></p>
                <p>Jede Person mit einem gültigen Vorverkaufsticket oder einem in bar gezahlten Eintrittsentgeldes am
                    Einlass ist grundsätzlich zur Teilnahme am Festival berichtigt.</p>
                <p>Davon ausgenommen sind minderjährige Personen, welche ohne Erziehungsberechtigten das Gelände
                    betreten
                    wollen. Personen, die illegale oder gefährliche Gegenständen mit sich führen, kann trotz
                    gezahltem
                    Entgeld der Zugang verweigert werden. Gefährlich Gegenstände sind insbesondere Waffen oder
                    waffenähnliche Gestände, oder Gegenstände die geeignet sind die Sicherheit des Festivals zu
                    gefährden
                    sowie Brand- oder Feuerwerkssätze.</p>
                <p>Davon ausgenommen sind zugelassene Grills und Grillzubehör, sofern keine kritische
                    Waldbrandgefahrenstufe vorliegt. Die Waldbrandgefahrenstufe kann im Vorfeld vom Veranstalter
                    abgefragt
                    werden.</p>
                <p>Das Sicherheitspersonal ist berechtigt und verpflichtet die Ladung von Fahrzeugen
                    stichprobenartig im
                    angemessen Rahmen am Einlass zu sichten. Der Gast stimmt dieser Maßnahme zu.</p>
                <p/>
                <p><b>§9 Haftung und Schadenersatz bei Durchführung des entropie Festivals</b></p>
                <p>Das Betreten des Festivalsgeländes und die damit verbundene Teilnahme am entropie Festival,
                    insbesondere die Nutzung von Aufbauten und Attraktionen auf dem Gelände geschieht ausdrücklich auf
                    eigene Gefahr!
                    Der Veranstalter haftet nicht für gesundheitliche oder persönliche Schäden die aus der Teilnahme am
                    Festival hervorgehen.</p>
                <p>Der Veranstalter haftet nicht für Beschädigung oder Verlust von Eigentum auf dem Festivalgelände.
                    Der Ausfall von vorher angekündigten Künstlern oder Musikereignissen berechtigen nicht zum
                    Schadenersatz, sofern die Durchführung der gesamten Veranstaltung noch gewährleistet ist. Es besteht
                    kein Rechtsanspruch auf bestimmte einzelne angekündigte Veranstaltungs- / Musikleistungen.
                    Kommt es aufgrund Ereignissen von höhere Gewalt bzw. durch Vorgaben von Sicherheitsbehörden des
                    Staates zu einer vorzeitigen Beendigung der Veranstaltung bzw. zu einem Entziehen der
                    Veranstaltungsgenehmigung, so ist der Veranstalter weder schadenersatzpflichtig noch haftbar zu
                    machen. Eine mögliche
                    Schadenersatzpflicht gegenüber des Staates ist davon unbenommen.</p>
                <p/>
                <p><b>§10 Eintrittsmarke</b></p>
                <p>Der Gast ist verpflichtet die am Einlass ausgehändigte Eintrittsmarke (Einlassbändchen) während der
                    Veranstaltung sichtbar, unbeschädigt und fest angebracht zu tragen und auf Verlangen
                    vorzuzeigen.</p>
                <p>Kommt es zum Verlust oder Beschädigung der Eintrittsmarke, so muss der Gast umgehend für Ersatz
                    sorgen.</p>
                <p>Beim Entfernen der Eintrittsmarke erlischt die Berechtigung an der Teilnahme des Festivals. Das
                    Weitergeben der Eintrittsmarke ist nicht erlaubt.</p>
                <p/>
                <p><b>§11 Ausschluss von der Veranstaltung</b></p>
                <p>Personen die ohne Eintrittsmarke nach §10 während des Festivalbetriebes auf dem Festivalgelände
                    (Bühnen, Campingbereich, eingezäunte oder markierte Bereiche) angetroffen werden machen sich des
                    Hausfriedensbruchs schuldig und werden umgehend des Geländes verwiesen. Personen, die die Anweisung
                    des Ordnungspersonals des entropie Festivals nicht befolgen, Straftaten begehen, die Sicherheit der
                    eigenen oder von fremden Personen vorsätzlich oder wiederholt gefährden oder mutwillig fremdes
                    Eigentum
                    beschädigt können nach Ermessen des Veranstalters bzw. seiner Vertreter von der Veranstaltung
                    unmittelbar ausgeschlossen werden. Auf dem Gelände verbliebenes Eigentum (Fahrzeuge, Zelte, ...) von
                    verwiesenen Personen können aus Sicherheitsgründen erst nach dem Festivalbetrieb wieder abgeholt
                    werden.</p>
                <p>Ausnahmen liegen allein im Ermessenspielraum des Veranstalters.</p>
                <p>Das Befahren mit motorisierten Fahrzeugen ist nur in vom Veranstalter zugewiesenen Bereichen des
                    Campingplatzes zur An- oder Abreise gestattet. Das Fahrzeug darf nur im nüchternen, ausgeruhten
                    Zustand geführt werden. Auf dem Gelände gilt die StVZO mit maximal Schrittgeschwindigkeit.
                    Fußgängern und allen anderen auf dem Gelände ist Vorrang zu gewähren.</p>
                <p/>
                <p><b>§12 Befahren des Festivalgeländes</b></p>
                <p>Das Befahren mit motorisierten Fahrzeugen ist nur in den kennzeichneten Bereichen des Campingplatzes
                    zur An- oder Abreise gestattet. Das Fahrzeug darf nur im nüchternen, ausgeruhten Zustand geführt
                    werden.</p>
                <p>Auf dem Gelände gilt die StVZO mit maximal Schrittgeschwindigkeit. Fußgängern und allen anderen auf
                    dem
                    Gelände ist Vorrang zu gewähren.</p>
                <p/>
                <p><b>§13 Campingplatz</b></p>
                <p>Der Gast ist verpflichtet den zur Nutzung überlassen Campingplatz sauber und im vorgefunden Zustand
                    zu hinterlassen. Das Zurücklassen von Sperrmüll, Fahrzeugen oder anderen Unrat ist nicht
                    erlaubt.</p>
                <p>Der Gast ist berechtigt, zu angekündigten Zeiten seine Müllpfandmarke und einen mit Müll gefüllten
                    Müllsack gegen das gezahlte Müllpfandgeld an angegeben Orten einzutauschen.</p>
                <p/>
                <p>Als Vertragssprache steht ausschließlich Deutsch zur Verfügung. Stand der AGB Feb. 2017.</p>
            </div>
        )
    }
}

export default AgbText;