import React from 'react';
import IntlHtmlMessages from "../../../util/IntlHtmlMessages";

class HeadingWithLine extends React.Component {
    render() {
        return (
            <div className={`HeadingWithLine${this.props.biggerPaddingBottom ? ' biggerPaddingBottom' : ''}`}>
                <div className={`line-left line-color-${this.props.lineColor}`} />
                <h2>
                    <IntlHtmlMessages id={this.props.textKey} values={this.props.textValues} />
                </h2>
            </div>
        )
    }
}

export default HeadingWithLine;