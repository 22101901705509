import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {TICKETING_GET_AVAILABLE_PRODUCTS_REQUEST} from "../config/actions";
import TicketingApi from '../apis/Ticketing';
import {loadAvailableProductsSuccess} from "../actions";

const loadAvailableProductsFromApi = async () =>
    TicketingApi.getAvailableProducts()
        .then((response) => {
            if(response.data.success) return response.data.data;
            return null;
        });

function* doLoadAvailableProducts() {
    try {
        const availableProducts = yield call(loadAvailableProductsFromApi);
        yield put(loadAvailableProductsSuccess(availableProducts));
    } catch (error) {
        console.log('error', error);
    }
}

export function* loadAvailableProducts() {
    yield takeEvery(TICKETING_GET_AVAILABLE_PRODUCTS_REQUEST, doLoadAvailableProducts);
}

export default function* rootSaga() {
    yield all([fork(loadAvailableProducts)]);
}