import React from 'react';
import IntlHtmlMessages from "../../../util/IntlHtmlMessages";

class TextBlockWithLine extends React.Component {
    render() {
        return (
            <div className="TextBlockWithLine">
                <div className={`line-left line-color-${this.props.lineColor}`} />
                <IntlHtmlMessages id={this.props.textKey} />
            </div>
        )
    }
}

export default TextBlockWithLine;