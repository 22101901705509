import React from 'react';
import IntlMessages from "../../../util/IntlMessages";
import {NavLink} from "react-router-dom";
import connect from "react-redux/src/connect/connect";

class FooterItem extends React.Component {
    render() {
        return (
            <li className={`FooterItem`}>
                <NavLink to={this.props.to} activeClassName="active" target={this.props.ticketing.isActive ? '_blank' : null}>
                    <IntlMessages id={this.props.titleKey} />
                </NavLink>
            </li>
        )
    }
}

const mapStateToProps = state => ({
    ticketing: state.ticketing
});

export default connect(mapStateToProps, {
})(FooterItem);