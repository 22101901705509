import axios from 'axios';
import Config from '../config/Config';

class Supporter {
    static register(firstname, lastname, phone, email, password, password2) {
        return axios.post(`${Config.api()}supporter/register`, {
            firstname,
            lastname,
            phone,
            email,
            password,
            password2
        });
    }

    static authenticate(email, password) {
        return axios.post(`${Config.api()}login`, {
            username: email,
            password: password
        })
    }

    static getWorktypes(bearerToken) {
        return axios.get(`${Config.api()}supporter/workTypes`, {
            headers: {
                authorization: `Bearer ${bearerToken}`
            }
        })
    }

    static setWorktypes(bearerToken, workTypes) {
        return axios.put(`${Config.api()}supporter/workTypes`, {
            workTypes
        }, {
            headers: {
                authorization: `Bearer ${bearerToken}`
            }
        })
    }
}

export default Supporter;
