import React from 'react';
import connect from "react-redux/src/connect/connect";
import {hidePopup2022} from "../../actions/Popup2022";
import closeImage from '../../assets/images/close.svg';
import closeMoImage from '../../assets/images/close_active.svg';

class Popup2022 extends React.Component {

    componentDidMount() {
        let element = document.getElementById('Popup2022');
        if (element) element.addEventListener('click', this.handleClose);
    }

    componentWillUnmount() {
        let element = document.getElementById('Popup2022');
        if (element) element.removeEventListener('click', this.handleClose);
    }

    handleClose = (e) => {
        if(e.target && e.target.id && e.target.id === "Popup2022-background-container") {
            this.props.hidePopup2022();
        }
    }

    render() {
        return this.props.popup2022.popup2022Visible && (
            <div id="Popup2022" className="popup2022-outer">
                <div id="Popup2022-background-container">
                    <div className="close close-top" onClick={this.props.hidePopup2022}>
                        <img src={closeImage} alt="" className="default"/>
                        <img src={closeMoImage} alt="" className="mouseover"/>
                    </div>
                    <div className="popup2022-content-container">
                        <div className="header-line">
                            <div className="title">
                                <h2>
                                    Festival verschoben!<br/>
                                    Postponed until 2022!
                                </h2>
                            </div>
                            <div className="language-links">
                                <a href="#lang-de">
                                    » Deutsch
                                </a>
                                <a href="#lang-en">
                                    » English
                                </a>
                                <a href="#lang-fr">
                                    » Français
                                </a>
                                <a href="#lang-ar" style={{direction: 'rtl', textAlign: 'left'}}>
                                    » عربى
                                </a>
                            </div>
                        </div>
                        <div className="content">
                            <div className="language-container" id="lang-de">
                                <div className="language">
                                    Deutsch
                                </div>
                                <div className="text">
                                    <h5>Leider kein entropiefestival 2021…<br/>
                                        wir verschieben auf 2022.</h5>

                                    Was ist lästiger als eine Seuche?<br/>
                                    Richtig, zwei Seuchen.<br/>
                                    Leider ist in der Region um die Klingemühle nun auch noch die Schweinepest ausgebrochen.<br/>
                                    Natürlich ist auch COVID-19 nach wie vor eine Bedrohung für unseren Veranstaltungstermin, doch hatten wir die Hoffnung, das Fest mit einem angemessenen Test- und Hygienekonzept möglich zu machen.<br/>
                                    Jetzt gibt es im Rahmen des Seuchenschutzes gleich doppelt verschärfte Auflagen im Friedland. Es tut uns im Herzen weh, doch leider müssen wir an dieser Stelle verkünden, dass die entropie ein weiteres Mal verschoben wird.<br/>
                                    <br/>
                                    Alle erworbenen Tickets, auch die von 2020, bleiben weiterhin gültig, bis das Festival stattfindet.<br/>
                                    Wenn ihr euer Ticket stornieren möchtet, wendet euch bitte mit euren Ticketnummern an <a href="mailto:ticketing@entropiefestival.de">ticketing@entropiefestival.de</a><br/>
                                    <br/>
                                    Alle Ticketkäufer:innen erhalten auch Informationen per E-Mail.
                                </div>
                            </div>
                            <div className="language-container" id="lang-en">
                                <div className="language">
                                    English
                                </div>
                                <div className="text">
                                    <h5>Sadly no entropiefestival 2021…<br/>
                                        We’re postponing until 2022!</h5>

                                    What’s even more tedious than a pandemic?<br/>
                                    You guessed it, two pandemics.<br/>
                                    Sadly, there has now also been a swine fever outbreak in the region around the Klingelmühle. Of course COVID-19 was in itself already a threat to our dates, but we still had the hope of staging the event with an appropriate testing and hygiene concept.<br/>
                                    In the course of the swine fever outbreak however, the statutory conditions from the town of Friedland have been doubled. It is therefore with a heavy heart that at this point we have to announce that the entropie will be postponed a second time.<br/>
                                    We will release the new dates for Summer 2022 in the coming months. We will again try to arrange it for the end of August.<br/>
                                    <br/>
                                    All purchased Tickets, in addition to those from 2020, will stay valid until the festival can take place.<br/>
                                    If you would like to receive a refund, please get in touch with your ticket numbers at <a href="mailto:ticketing@entropiefestival.de">ticketing@entropiefestival.de</a><br/>
                                    <br/>
                                    All ticket holders will be receiving this information via email shortly.
                                </div>
                            </div>
                            <div className="language-container" id="lang-fr">
                                <div className="language">
                                    Francais
                                </div>
                                <div className="text">
                                    <h5>Malheureusement pas d’entropie festival cette année …<br/>
                                        nous reportons à 2022!</h5>


                                    Quoi de plus ennuyeux qu’une épidémie?<br/>
                                    Deux épidémies!<br/>
                                    La peste porcine a malheureusement éclaté dans la région autour du Klingemühle.<br/>
                                    Bien sûr, le COVID-19 était toujours une menace pour le maintien de la date de notre événement, mais nous avions l’espoir de rendre le festival possible grâce à un concept de tests et d’hygiène approprié.<br/>
                                    Malheureusement, il y a désormais des exigences doublement plus strictes à Friedland dans le cadre de la protection contre les épidémies.<br/>
                                    C’est donc avec beaucoup de regrets que nous devons annoncer que l’entropie Festival sera repoussé une fois de plus.<br/>
                                    Nous annoncerons dans les prochains mois la nouvelle date de l’été 2022. Nous essaierons de viser à nouveau fin août.<br/>
                                    <br/>
                                    Tous les billets achetés, y compris ceux de 2020, resteront valables jusqu’à la tenue du festival.<br/>
                                    Si vous souhaitez annuler votre billet, veuillez contacter <a href="mailto:ticketing@entropiefestival.de">ticketing@entropiefestival.de</a> avec vos numéros de billet.<br/>
                                    <br/>
                                    Tous les acheteurs de billets recevront bientôt des informations par e-mail.
                                </div>
                            </div>
                            <div className="language-container" id="lang-ar" style={{direction: 'rtl'}}>
                                <div className="language">
                                    عربى
                                </div>
                                <div className="text">
                                    <h5>لا مهرجان „Entropie“ في 2021 !  تم تأجيله لسنة2022 !</h5>

                                    ما الأكثر إزعاجا من وباء ؟ صحيح: وباءان في آن واحد. للأسف,تفشت  حمى الخنازير مؤخرا في المنطقة حول  „Klingemühle“ في حين ظل كوفيد 19 يشكل تهديدا لإقامة المهرجان. لقد كنا نتمنى أن نتمكن  و بتدابير صحية و أمنية مناسبة إقامة المهرجان. ولكن الشروط الأكثر صرامةً لقانون مكافحة العدوى تجعل و للسنة الثانية إقامة الحدث قي عام 2021من المستحيل.<br/>
                                    سنعلن قريباً عن موعد جديد في ل „Entropie“2022 ونأمل أن يكون هذا في وقت مماثل لما كان مقررا هذا العام.<br/>
                                    وتظل جميع التذاكر التي تم شراؤها ، بما فيها التذاكر التي تم شراؤها منذ عام 2020 ، صالحة إلى أن يتم الحدث. كما ان إلغاء التذاكر ممكن أيضا. في هذه الحالة ، الرجاء إرسال بريد إلكتروني مع رقم التذكرة إلى <a href="mailto:ticketing@entropiefestival.de">ticketing@entropiefestival.de</a> .<br/>
                                    مع حب<br/>
                                    الى مستقبل خالي من أوبئة<br/>
                                    أسرة مهرجان„Entropie“
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="close close-bottom" onClick={this.props.hidePopup2022}>
                        <img src={closeImage} alt="" className="default"/>
                        <img src={closeMoImage} alt="" className="mouseover"/>
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = state => ({
    popup2022: state.popup2022
});

export default connect(mapStateToProps, {
    hidePopup2022
})(Popup2022);