export const TICKETING_GET_AVAILABLE_PRODUCTS_REQUEST = 'TICKETING_GET_AVAILABLE_PRODUCTS_REQUEST';
export const TICKETING_GET_AVAILABLE_PRODUCTS_SUCCESS = 'TICKETING_GET_AVAILABLE_PRODUCTS_SUCCESS';
export const TICKETING_SAVE_STEP_1 = 'TICKETING_SAVE_STEP_1';
export const TICKETING_SAVE_STEP_2 = 'TICKETING_SAVE_STEP_2';
export const TICKETING_SAVE_STEP_3 = 'TICKETING_SAVE_STEP_3';
export const TICKETING_BACK_TO_STEP_1 = 'TICKETING_BACK_TO_STEP_1';
export const TICKETING_BACK_TO_STEP_2 = 'TICKETING_BACK_TO_STEP_2';
export const TICKETING_CLEAR_STATE = 'TICKETING_CLEAR_STATE';
export const TICKETING_PAYPAL_SUCCESS = 'TICKETING_PAYPAL_SUCCESS';
export const TICKETING_PAYPAL_ERROR = 'TICKETING_PAYPAL_ERROR';

export const LOADER_SHOW = 'LOADER_SHOW';
export const LOADER_HIDE = 'LOADER_HIDE';
export const LOADER_SHOW_ERROR = 'LOADER_SHOW_ERROR';

export const SETTINGS_LANGUAGE_TOGGLE = 'SETTINGS_LANGUAGE_TOGGLE';
export const SETTINGS_SET_UUID = 'SETTINGS_SET_UUID';
export const SETTINGS_SET_LAST_ACTION = 'SETTINGS_SET_LAST_ACTION';

export const LOGIN_SET_BEARER_TOKEN = 'LOGIN_SET_BEARER_TOKEN';
export const LOGIN_LOGOUT = 'LOGIN_LOGOUT';

export const GLOBAL_CLEAR_STATE = 'GLOBAL_CLEAR_STATE';

export const SUPPORTER_SET_AUTHENTICATION = 'SUPPORTER_SET_AUTHENTICATION';
export const SUPPORTER_LOGOUT = 'SUPPORTER_SET_BEARER_TOKEN';

export const POPUP_2022_SHOW = 'POPUP_2022_SHOW';
export const POPUP_2022_HIDE = 'POPUP_2022_HIDE';