/* eslint-disable jsx-a11y/href-no-hash */
import appLocaleData from 'react-intl/locale-data/de';
import enMessages from '../locales/en_EN';

const EnLang = {
  messages: {
    ...enMessages
  },
  locale: 'en-EN',
  data: appLocaleData
};
export default EnLang;
