import React from 'react';
import UsageApi from '../../../apis/Usage';
import connect from "react-redux/src/connect/connect";
import {setLastAction} from "../../../actions";

class UsagePageLoad extends React.Component {

    componentDidMount() {
        UsageApi.sendPageLoad(this.props.settings.uuid);
    }

    render () {
        return null
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

export default connect(mapStateToProps, {
    setLastAction
})(UsagePageLoad);