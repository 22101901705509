import React from 'react';
import InstagramIcon from '../../assets/images/social/instagram.svg';
import InstagramIconWhite from '../../assets/images/social/instagram_white.svg';
import FacebookIcon from '../../assets/images/social/facebook.svg';
import FacebookIconWhite from '../../assets/images/social/facebook_white.svg';
import PodcastIcon from '../../assets/images/social/podcast.svg';
import {Link} from "react-router-dom";

class SocialMenu extends React.Component {
    render() {
        return (
            <div className="SocialMenu">
                <a href="https://facebook.com/entropiefestival" target="_blank" className="facebook-link" rel="noopener noreferrer">
                    <img src={this.props.whiteMode ? FacebookIconWhite : FacebookIcon} alt="Facebook"/>
                </a>
                <a href="https://www.instagram.com/entropiefestival/" target="_blank" className="instagram-link" rel="noopener noreferrer">
                    <img src={this.props.whiteMode ? InstagramIconWhite : InstagramIcon} alt="Instagram"/>
                </a>
                <Link to="/podcast" className="podcast-link">
                    <img src={PodcastIcon} alt="Podcast"/>
                </Link>
            </div>
        )
    }
}

export default SocialMenu;
