import {LOGIN_LOGOUT, LOGIN_SET_BEARER_TOKEN} from "../config/actions";

export const setBearerToken = (bearerToken) => {
    return {
        type: LOGIN_SET_BEARER_TOKEN,
        bearerToken
    }
};

export const logout = () => {
    return {
        type: LOGIN_LOGOUT
    }
};