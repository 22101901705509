import {
    TICKETING_BACK_TO_STEP_1, TICKETING_BACK_TO_STEP_2, TICKETING_CLEAR_STATE,
    TICKETING_GET_AVAILABLE_PRODUCTS_REQUEST,
    TICKETING_GET_AVAILABLE_PRODUCTS_SUCCESS, TICKETING_PAYPAL_ERROR, TICKETING_PAYPAL_SUCCESS,
    TICKETING_SAVE_STEP_1, TICKETING_SAVE_STEP_2, TICKETING_SAVE_STEP_3
} from "../config/actions";

export const loadAvailableProducts = () => ({
    type: TICKETING_GET_AVAILABLE_PRODUCTS_REQUEST
});

export const loadAvailableProductsSuccess = availableProducts => ({
    type: TICKETING_GET_AVAILABLE_PRODUCTS_SUCCESS,
    payload: availableProducts
});

export const saveStep1 = selectedProducts => ({
    type: TICKETING_SAVE_STEP_1,
    payload: selectedProducts
});

export const saveStep2 = order => ({
    type: TICKETING_SAVE_STEP_2,
    payload: order
});

export const saveStep3 = orderSummary => ({
    type: TICKETING_SAVE_STEP_3,
    payload: {
        orderSummary
    }
});

export const backToStep1 = () => ({
    type: TICKETING_BACK_TO_STEP_1
});

export const backToStep2 = () => ({
    type: TICKETING_BACK_TO_STEP_2
});

export const clearState = () => ({
    type: TICKETING_CLEAR_STATE
});

export const paypalSuccess = (payload) => ({
    type: TICKETING_PAYPAL_SUCCESS,
    payload
});

export const paypalError = () => ({
    type: TICKETING_PAYPAL_ERROR
});