import React from 'react';

class ImprintText extends React.Component {
    render() {
        return (
            <div className="ImprintText">
                <div className={`line-left line-color-pink`} />
                <p><b>kulturevolution e.V.</b></p>
                <p><b>Großbeerenstraße 12</b></p>
                <p><b>10963 Berlin</b></p>
                <p/>
                <p><b>Telefon: +49 175 3532487</b></p>
                <p><b>E-Mail: mail@kulturevolution.org</b></p>
                <p/>
                <p><b>Vertreten durch:</b></p>
                <p>Volkram Weber, Rouven Walter, Pascal Weber, Toni Tress, Denise Hamann, Nils Berg, Oscar
                    Machel-Ebeling</p>
                <p/>
                <p><b>Design:</b></p>
                <p>Aenias Fritsch mail@aeniasfritsch.de</p>
                <p/>
                <p><b>Hinweis auf EU-Streitschlichtung</b></p>
                <p>
                    Zur außergerichtlichen Beilegung von verbraucherrechtlichen Streitigkeiten hat die Europäische Union
                    eine Online-Plattform (“OS-Plattform”) eingerichtet, an die Sie sich wenden können. Die Plattform
                    finden Sie unter http://ec.europa.eu/consumers/odr/. Unsere Emailadresse lautet:
                    mail@kulturevolution.org
                </p>
                <p/>
                <h4>Disclaimer – rechtliche Hinweise</h4>
                <p><b>§ 1 Warnhinweis zu Inhalten</b></p>
                <p>
                    Die kostenlosen und frei zugänglichen Inhalte dieser Webseite wurden mit größtmöglicher Sorgfalt
                    erstellt. Der Anbieter dieser Webseite übernimmt jedoch keine Gewähr für die Richtigkeit und
                    Aktualität
                    der bereitgestellten kostenlosen und frei zugänglichen journalistischen Ratgeber und Nachrichten.
                    Namentlich gekennzeichnete Beiträge geben die Meinung des jeweiligen Autors und nicht immer die
                    Meinung
                    des Anbieters wieder. Allein durch den Aufruf der kostenlosen und frei zugänglichen Inhalte kommt
                    keinerlei Vertragsverhältnis zwischen dem Nutzer und dem Anbieter zustande, insoweit fehlt es am
                    Rechtsbindungswillen des Anbieters.
                </p>
                <p/>
                <p><b>§ 2 Externe Links</b></p>
                <p>
                    Diese Website enthält Verknüpfungen zu Websites Dritter („externe Links“). Diese Websites
                    unterliegen
                    der Haftung der jeweiligen Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der externen
                    Links die fremden Inhalte daraufhin überprüft, ob etwaige Rechtsverstöße bestehen. Zu dem Zeitpunkt
                    waren keine Rechtsverstöße ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle und
                    zukünftige Gestaltung und auf die Inhalte der verknüpften Seiten. Das Setzen von externen Links
                    bedeutet
                    nicht, dass sich der Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen macht.
                    Eine
                    ständige Kontrolle der externen Links ist für den Anbieter ohne konkrete Hinweise auf Rechtsverstöße
                    nicht zumutbar. Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe Links unverzüglich
                    gelöscht.
                </p>
                <p/>
                <p><b>§ 3 Urheber- und Leistungsschutzrechte</b></p>
                <p>
                    Die auf dieser Website veröffentlichten Inhalte unterliegen dem deutschen Urheber- und
                    Leistungsschutzrecht. Jede vom deutschen Urheber- und Leistungsschutzrecht nicht zugelassene
                    Verwertung
                    bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder jeweiligen Rechteinhabers. Dies
                    gilt
                    insbesondere für Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung, Verarbeitung bzw.
                    Wiedergabe von Inhalten in Datenbanken oder anderen elektronischen Medien und Systemen. Inhalte und
                    Rechte Dritter sind dabei als solche gekennzeichnet. Die unerlaubte Vervielfältigung oder Weitergabe
                    einzelner Inhalte oder kompletter Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung
                    von
                    Kopien und Downloads für den persönlichen, privaten und nicht kommerziellen Gebrauch ist erlaubt.
                    Die
                    Darstellung dieser Website in fremden Frames ist nur mit schriftlicher Erlaubnis zulässig.
                </p>
                <p/>
                <p><b>§ 4 Besondere Nutzungsbedingungen</b></p>
                <p>
                    Soweit besondere Bedingungen für einzelne Nutzungen dieser Website von den vorgenannten Paragraphen
                    abweichen, wird an entsprechender Stelle ausdrücklich darauf hingewiesen. In diesem Falle gelten im
                    jeweiligen Einzelfall die besonderen Nutzungsbedingungen.
                </p>
                <p/>
                <p>Quelle: www.juraforum.de</p>
            </div>
        )
    }
}

export default ImprintText;
