import React, {Component, Fragment} from 'react';
import '../assets/stylesheets/app.scss';
import {Route, Router, Switch} from "react-router";
import {IntlProvider} from 'react-intl'
import AppLocale from '../lngProvider';
import Home from "./Home";
import Info from "./Info";
import Lineup from "./Lineup";
import Legals from "./Legals";
import NewsletterConfirm from "./NewsletterConfirm";
import Supporter from "./Supporter";
import Ticketing from "./Ticketing";
import Loader from "../components/elements/Loader";
import CookieBanner from '../components/elements/CookieBanner';
import connect from "react-redux/src/connect/connect";
import Login from "./Login";
import Preload from 'react-preload';
import piInfoActs1 from '../assets/images/info/info-acts-1.jpg';
import piInfoActs2 from '../assets/images/info/info-acts-2.jpg';
import piInfoActsMobileMic from '../assets/images/info/info-acts-mobile-mic.jpg';
import piPhilo1 from '../assets/images/philosophy/philosophy-1.jpg';
import piPhilo2 from '../assets/images/philosophy/philosophy-2.jpg';
import piRBridge from '../assets/images/right/right-bridge.jpg';
import piRDrums from '../assets/images/right/right-drums.jpg';
import piRGuitars from '../assets/images/right/right-guitars.png';
import piRMic from '../assets/images/right/right-mic.jpg';
import piRMixer from '../assets/images/right/right-mixer.png';
import piBackgroundImage from "../assets/images/home/background.gif";
import piRTurntable from '../assets/images/right/right-turntable.jpg';
import UsagePageLoad from "../components/elements/Usage/UsagePageLoad";
import UsageApi from '../apis/Usage';
import {hideLoader, setLastAction, showLoader} from "../actions";
import {clearAllBodyScrollLocks} from "body-scroll-lock/lib/bodyScrollLock.es6";
import isTouchDevice from 'is-touch-device';
import Podcast from "./Podcast";
import Popup2022 from "../components/elements/Popup2022";


class App extends Component {

    state = {
        layout: 'desktop',
        currentPath: window.location.pathname,
        isTouchDevice: false
    };

    updateLayout = () => {
        let windowWidth = window.innerWidth;
        let newLayout = 'desktop';
        if (windowWidth < 1700) {
            newLayout = 'desktopSmall';
            clearAllBodyScrollLocks();
        }

        if (windowWidth < 1280) {
            newLayout = 'tablet';
        }
        if (windowWidth < 768) {
            newLayout = 'mobile';
        }

        this.setState({
            layout: newLayout,
            isTouchDevice: isTouchDevice()
        })
    };

    componentWillMount() {
        this.props.showLoader();
        this.updateLayout();
        window.addEventListener("resize", this.updateLayout);
        document.body.classList.remove(this.props.settings.locale === 'de' ? 'en' : 'de');
        document.body.classList.add(this.props.settings.locale === 'en' ? 'en' : 'de');

        this.unlistenHistory = this.props.history.listen((location, action) => {
            UsageApi.sendNavigation(this.props.settings.uuid, location.pathname, this.state.currentPath);
            this.setState({
                currentPath: location.pathname
            });
        });
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateLayout);

        this.unlistenHistory();
    }

    render() {
        const currentAppLocale = AppLocale[this.props.settings.locale];
        let images = [piInfoActs1, piInfoActs2, piInfoActsMobileMic, piPhilo1, piPhilo2, piRBridge, piRDrums, piRGuitars, piRMic, piRMixer, piRTurntable];
        return (
            <IntlProvider
                locale={currentAppLocale.locale}
                messages={currentAppLocale.messages}>
                <Fragment>
                    <Loader/>
                    <Preload onSuccess={this.props.hideLoader} images={[piBackgroundImage]} children={
                        <div
                            className={`App layout-${this.state.layout} is${!this.state.isTouchDevice ? 'Not' : ''}TouchDevice`}>
                            {/*<CookieBanner/>*/}
                            <Popup2022/>
                            <UsagePageLoad/>
                            <Preload loadingIndicator={null} images={images} children={<div/>}/>
                            <Router history={this.props.history}>
                                <Switch>
                                    <Route path="/tickets" component={Ticketing}/>
                                    <Route path="/supporter" component={Supporter}/>
                                    <Route path="/newsletter/confirm/:token" component={NewsletterConfirm}/>
                                    <Route path="/legals" component={Legals}/>
                                    <Route path="/lineup" component={Lineup}/>
                                    <Route path="/awareness" render={() => <Info topicAwareness/>}/>
                                    <Route path="/info/:topic?" component={Info}/>
                                    <Route path="/newsletter" component={Info}/>
                                    <Route path="/login" component={Login}/>
                                    <Route path="/podcast" component={Podcast}/>
                                    <Route path="/" render={() => (<Home currentLayout={this.state.layout}/>)}/>
                                </Switch>
                            </Router>
                        </div>
                    }/>
                </Fragment>
            </IntlProvider>
        );
    }
}

const mapStateToProps = state => ({
    settings: state.settings
});

export default connect(mapStateToProps, {
    setLastAction, hideLoader, showLoader
})(App);
