import {
    GLOBAL_CLEAR_STATE,
    SETTINGS_LANGUAGE_TOGGLE, SETTINGS_SET_LAST_ACTION, SETTINGS_SET_UUID
} from "../config/actions";

const INIT_STATE = {
    locale: 'de',
    uuid: uuidv4(),
    lastActionAt: new Date().getTime()
};

export default (state = INIT_STATE, action) => {
    switch(action.type) {
        case SETTINGS_LANGUAGE_TOGGLE: {
            return {
                ...state,
                locale: state.locale === 'de' ? 'en' : 'de'
            };
        }
        case SETTINGS_SET_UUID:
            return {
                ...state,
                uuid: uuidv4()
            };
        case GLOBAL_CLEAR_STATE:
        case SETTINGS_SET_LAST_ACTION:
            return {
                ...state,
                lastActionAt: new Date().getTime()
            };
        default:
            return state;
    }
}

function uuidv4() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        let r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}