/* eslint-disable jsx-a11y/href-no-hash */
import appLocaleData from 'react-intl/locale-data/de';
import deMessages from '../locales/de_DE.json';

const DeLang = {
  messages: {
    ...deMessages
  },
  locale: 'de-DE',
  data: appLocaleData
};
export default DeLang;
