import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import Ticketing from './Ticketing';
import Loader from './Loader';
import Settings from './Settings';
import Login from "./Login";
import Supporter from "./Supporter";
import Popup2022 from "./Popup2022";


const reducers = combineReducers({
    routing: routerReducer,
    ticketing: Ticketing,
    loader: Loader,
    settings: Settings,
    login: Login,
    supporter: Supporter,
    popup2022: Popup2022
});

export default reducers;
