import React from 'react';

class PodcastContent {
    static entries() {
        return [
            {
                season: 2,
                orderNumber: 5,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast015',
                info: <div>
                    Alles neu macht der Mai. Unter dieser Maxime liefert unser Resident @Liebstern ein facettenreiches Set mit neuen und exclusiven Tunes sowie großartigen Oldschool Bangern. Jetzt kann der Sommer endlich kommen!<br/>
                    <br/>
                    Everything is new in May. This German proverb was the maxim for our resident @Liebstern to record this versatile set. Packed with great oldschool bangers plus new and exclusive material. Now we're ready for summer!
                </div>
            },
            {
                season: 2,
                orderNumber: 4,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast014',
                info: <div>
                    Der Frühling 2021 bringt uns die ersten warmen Tage und einen exzellenten Dubtechno Mix ins Haus. Genosse Zeit, der eigentlich eher dafür bekannt ist den Trancefloor zu rocken, zeigt uns heute mit Bravour dass es auch andere Seiten in seinem Repertoire gibt. Wir sind sehr glücklich darüber, ihn zu unseren Resident DJ's zählen zu dürfen und freuen uns riesig darüber diese musikalische Perle mit euch teilen zu können.<br/>
                    <br/>
                    Spring 2021 comes with the first warm days and an excellent Dubtechno mix. Genosse Zeit, who is actually more known for rocking the trance floor, is showing us today with excellence that there are also other sides in his repertoire. We are absolutely pleased to have him as one of our resident DJ's and very happy to be able to share this musical highlight with you.
                </div>
            },
            {
                season: 2,
                orderNumber: 3,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast013',
                info: <div>
                    Mit einem Downtempo Special, gibt unser entropie Resident Juelito einen musikalischen Einblick in das emotionale Auf und Ab der aktuellen Zeit. Mal melancholisch, mal hoffnungsvoll aufstrebend, teilt er mit uns seine Gefühle. Also spitzt die Ohren und genießt die musikalische Reise.<br/>
                    <br/>
                    With a downtempo special, our entropie resident Juelito gives a musical insight into the emotional ups and downs of the current time. Sometimes melancholic, sometimes hopeful aspiring, he shares his feelings. So listen up and enjoy the musical journey.
                </div>
            },
            {
                season: 2,
                orderNumber: 2,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast12',
                info: <div>
                    Mit einem hochenergetischen Mix, starten wir in diesen Februar. Leicht und locker, lässt uns PrAiSe durch die dunkle Jahreszeit tanzen. Mit ihrem unverkennbaren Tech-House Sound, erinnert sie uns daran, dass trotz beißender Kälte, die Tage wieder länger werden und der Frühling in greifbare Nähe rückt. Wir drehen jetzt jedenfalls die Heizung auf, ziehen unser Sommeroutfit an und machen das Wohnzimmer zum Dancefloor.<br/>
                    <br/>
                    February kicks off with a high-energy mix that lets us dance gently through the dark season. With her unmistakable tech-house sound, Praise reminds us that despite the biting cold, the days are getting longer again and spring is within reach. In any case, we are now turning up the heater, putting on our summer outfits and transforming the living room into a dance floor.
                </div>
            },
            {
                season: 2,
                orderNumber: 1,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast011',
                info: <div>
                    Mit einem fetten musikalischen Knaller, starten wir hoffnungsvoll in das neue Jahr. 2020 ist abgehakt und ohne zurück zu schauen, gehen wir zuversichtlich auf einen tollen Festivalsommer 2021 zu.<br/>
                    @pfaukarma präsentiert uns zum Start der zweiten Staffel, einen verspielt warmen und sehr tanzbaren Ausblick auf diesen. Lasst uns zusammen von durchtanzten Nächten träumen und mit einem optimistisch freudigen Lächeln das neue Jahr begrüßen.<br/>
                    <br/>
                    We are starting the new year with a fat musical banger. 2020 is gone and without looking back, we go confidently towards a great festival summer 2021.<br/>
                    For the start of the new season entropieCast @pfaukarma presents us a playfully warm and very danceable outlook on the summer. Let's dream together of dancing through the night and welcome the new year with an optimistic smile.<br/>
                    <br/>
                    Pic - Jaqueline Louan<br/>
                    Design - Entropie Festival
                </div>
            },
            {
                season: 1,
                orderNumber: 10,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast010',
                info: <div>
                    In der letzten Folge der ersten Staffel, gibt es nochmal einen ganz besonderen Leckerbissen.<br/>
                    Neben ihrem Mitwirken bei der Solipartyreihe der interventionistischen Linken CHOOSE:, steht das Berliner Duo Bisi & Lasta natürlich auch selber hinter den Reglern. Mit einem gekonnten Mix aus Techno und Techhouse, bringen sie jeden Floor zum kochen, was sie letztes Jahr eindrucksvoll in der Räuberhöhle auf dem Fusion Festival gezeigt haben. @bisi-und-lasta<br/>
                    <br/>
                    WAS HABT IHR IN LETZTER ZEIT SO GEMACHT?<br/>
                    <br/>
                    Briefmarkensammlung sortiert, Gartenhecke gestutzt, gegen rassistische Polizeigewalt demonstriert, den Techno unterm Strand gesucht und oben drauf ne ordentliche Portion social disdancing.<br/>
                    <br/>
                    WAS IST DIE IDEE HINTER DEM MIX?<br/>
                    <br/>
                    Wie immer mit fetzigen Basslines nach vorne...dieses Mal voller Sehnsucht nach den lauten, schönen und kollektiven Momenten, die wir alle so schmerzlich vermissen.<br/>
                    <br/>
                    WAS HABT IHR DEMNÄCHST SO GEPLANT?<br/>
                    <br/>
                    Urlaub im Süden, raven an Friedlands Klingemühle, die Weltrevolution....und ein Corona-safes Soli Open Air mit lieben Menschen und feinster elektronischer Tanzmusik.
                </div>
            },
            {
                season: 1,
                orderNumber: 9,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast009',
                info: <div>
                    In Folge 009, begrüßen wir Isi aka katzengold. Neben dem Mitwirken beim Berliner Kollektiv @kom-bass, ist sie für ihre melodisch träumerischen Sets bekannt. Ihr warmer, aber dennoch treibender Sound, lässt uns an die ersten wärmenden Strahlen eines Festival - Sonnenaufgangs denken und gemeinschaftlich davonschweben. Durch ihre überzeugende Trackauswahl und viel Fingerspitzengefühl beim Auflegen, geht ihr Sound ganz unbeschwert vom Kopf übers Herz direkt in die Füße. @katzen_gold<br/>
                    <br/>
                    WAS HAST DU IN LETZTER ZEIT SO GEMACHT?<br/>
                    <br/>
                    Ich saß wegen Corona vier Monate in Thailand fest, wofür ich aber unglaublich dankbar war. In dieser Zeit konnte ich mich viel mit Musik beschäftigen und habe auch angefangen produzieren zu lernen. Zurück in Berlin muss ich das Ganze erstmal mit zwei Jobs wieder finanziell ausgleichen, aber was solls!<br/>
                    <br/>
                    WAS IST DIE IDEE HINTER DEM MIX?<br/>
                    <br/>
                    Ich liebe emotionalen Sound mit Tiefe und einer Geschichte die erzählt wird. Natürlich ist die Geschichte voller Gefühl und tanzbaren Melodien. Manchmal melancholisch, dann wieder fröhlich. Aber Hauptsache das Herz und das Tanzbein wird berührt!<br/>
                    <br/>
                    WAS HAST DU DEMNÄCHST SO GEPLANT?<br/>
                    <br/>
                    Weiter viel arbeiten(leider), mit Freunden und Kollektiven Projekte in der Zukunft planen. Weitere Streams und Podcasts sind auch geplant. Die Zeit sollte man auf jeden Fall nutzen produktiv zu werden, so eine Chance kommt so schnell nicht nochmal. Versuchen das Beste aus der Situation zu machen!<br/>
                    <br/>
                    Tracklist:<br/>
                    <br/>
                    Strinner – Equilibria<br/>
                    Strinner – Slant<br/>
                    Joris Voorn – Never (Colyn Remix)<br/>
                    Miyagi – White Widow<br/>
                    GAM3 – Messenger of God (Pindura Remix)<br/>
                    Earthlife – La Veritá Del Tempo<br/>
                    Dizharmonia – Ehetleos<br/>
                    Marius Drescher – Momentum<br/>
                    Lincklaen – Amalgam (Jaden Raxel Remix)<br/>
                    Faithless – We Come One (Senses Of Mind Edit)<br/>
                    Darko Milosevic & Nick Devon – Corpus Hermeticus<br/>
                    Alberth – The Landing (The Ancient Memories Remix)<br/>
                    Woo York – Odyssey<br/>
                    Alberth – Solstice (Lunar Plane Remix)<br/>
                    Soul Button & Strinner – Oracle<br/>
                    DECIDUOUS – Daze<br/>
                    Mila Journée – Lux
                </div>
            },
            {
                season: 1,
                orderNumber: 8,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast008',
                info: <div>
                    Gieszack's Sound schreit förmlich nach einem dunklen Floor, Strobo, Nebel und einer drückenden Anlage. Leider können wir diesen Sound aktuell nicht in seinem natürlichen Habitat genießen, aber genau deshalb kommt dieser entropieCast gerade genau richtig, denn er hilft uns diese üble Durststrecke stan­des­ge­mäß zu überstehen und lässt uns gemeinsam von schweißgebadeten Tanzexzessen träumen. @gieszack<br/>
                    <br/>
                    WAS HAST DU IN LETZTER ZEIT SO GEMACHT?<br/>
                    <br/>
                    Entspannt, gearbeitet und das Leben genossen!<br/>
                    <br/>
                    WAS IST DIE IDEE HINTER DEM MIX?<br/>
                    <br/>
                    Wie bei vielen meiner Mixe, wollte ich spielen was mir zu diesem Moment gefallen hat und nicht was gerade neu ist. Der älteste Track im Set ist von 1996, der neuste von 2020!<br/>
                    <br/>
                    WAS HAST DU DEMNÄCHST SO GEPLANT?<br/>
                    <br/>
                    Morgen gehe ich erstmal einen Sekt trinken, weiter kann ich schlecht planen...<br/>
                    <br/>
                    Tracklist:<br/>
                    <br/>
                    Alex Cortex - We Run Your Life (Original Mix)<br/>
                    Alex Cortex - Hi-Mem (Original Mix)<br/>
                    François X - Intersection (Original Mix)<br/>
                    Jeroen Liebregts - Hunter/Grazer (Original Mix)<br/>
                    DJ Boss / Bas Mooy - Bokkenpruik (Original Mix)<br/>
                    Arthur Robert - Fragnant (Original Mix)<br/>
                    Arthur Robert - Percision (Original Mix)<br/>
                    Fundamental Interaction - Combined (Original Mix)<br/>
                    Gieszack - Darkroom Monastery Intervention (Unmastered)<br/>
                    Luis Ruiz / Reeko - Dilmun (Reeko Remix)<br/>
                    $turmtech vs Maukook - What 2019 Trance Sounds Like (Original Mix)<br/>
                    Elad Magdasi - In Your Eyes (Original Mix)<br/>
                    Zombie Nation - Kernkraft 400 (Original Mix)
                </div>
            },
            {
                season: 1,
                orderNumber: 7,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast007',
                info: <div>
                    Slowhouse, Schneckno, Ketapop, Bummeltechno oder schlicht Downtempo. Skarú's Musik ist all das und doch so viel mehr. Ob im Club, zum Festival oder zur Afterhour, mit diesem Mix zeigt skarú die verführerisch schöne Vielschichtigkeit dieses Genres. Ohne zu übertreiben, gelingt es ihm mühelos jedes Setting in einen dahinwabernden Traum zu verwandeln und alles in Hörweite in seinen Bann zu ziehen.<br/>
                    @skarusears<br/>
                    <br/>
                    WAS HAST DU IN LETZTER ZEIT SO GEMACHT?<br/>
                    <br/>
                    Wie ein Faultier gelebt. Oft bin ich in der Natur, dabei stets langsam unterwegs, nasche Kräuter und beobachte allerlei Kleingetier. Dann und wann wird musiziert, manchmal aber auch nur herumgesessen und lange geweilt.<br/>
                    <br/>
                    WAS IST DIE IDEE HINTER DEM MIX?<br/>
                    <br/>
                    Das Set ist angelehnt an meinen letzten Gig im Mensch Meier, diesen Februar. Bisher gibt es von mir nur verträumte Podcasts für die ruhigen Momente online zu hören. Um zu zeigen was ich im Club so alles spiele, wird es künftig mehr Recordings geben.<br/>
                    <br/>
                    WAS HAST DU SO DEMNÄCHST GEPLANT?<br/>
                    <br/>
                    So einiges, zum Beispiel zetteln ein paar Freund*innen und ich am Wochenende eine Aktion gegen das Kohleverlängerungsgesetz an. Wer sich auch am Aktionswochenende beteiligen möchte, findet alle nötigen Infos unter www.ende-gelaende.org
                </div>
            },
            {
                season: 1,
                orderNumber: 6,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast006',
                info: <div>
                    Der entropieCast 006 lässt die Hoffnung auf durchtanzte Nächte aufleben und bringt uns frisches Festivalfeeling ins Wohnzimmer.<br/>
                    Mit diesem äußerst tanzbaren Set, lässt uns @GringosCat vom nächsten Endorphin getränkten entropiefestival träumen.<br/>
                    <br/>
                    WAS HAST DU IN LETZTER ZEIT SO GEMACHT?<br/>
                    <br/>
                    Aufgrund der momentanen Lage, musste ich wochenlang schwitzen weil ich nicht arbeiten konnte. Seit kurzem kann ich wieder arbeiten, aber schwitzen muss ich immer noch...<br/>
                    <br/>
                    WAS IST DIE IDEE HINTER DEM MIX?<br/>
                    <br/>
                    Der Begriff Entropie hat mich inspiriert meine Bibliothek zu durchforsten und einen musikalischen Einblick meiner Mixe für euch zu kreieren.<br/>
                    <br/>
                    WAS HAST DU SO DEMNÄCHST GEPLANT?<br/>
                    <br/>
                    Dadurch dass diesen Sommer ja kein Festival stattfinden wird, werde ich wohl etwas mehr die sonnigen Tage als die bunten Nächte in der Natur genießen.<br/>
                    <br/>
                    Tracklist:<br/>
                    <br/>
                    Sasse, Stelios Vassiloudis - Refuse (Dub Mix)<br/>
                    Michael Melchner - Four to the Floor (Matthias Tanzmann Remix)<br/>
                    Mihai Popoviciu, Markus Homm - Insomnis (Original Mix)<br/>
                    Metodi Hristov - Rough Play (Original Mix)<br/>
                    Bizen Lopez - Analog Vibes (Original Mix)<br/>
                    Kled Baken - Forever Vintage (Original Mix)<br/>
                    Angel Heredia - Touch Me (Original Mix)<br/>
                    Rene Amesz - Vispas (Extended Mix)<br/>
                    Kled Baken - Barcelona Don't Sleep (Original Mix)<br/>
                    Oscar L - Unique (Original Mix)<br/>
                    Olivier Giacomotto - Dusk (Original Mix)<br/>
                    Technasia - I Am Somebody (Original Mix)<br/>
                    Rino Cerrone - Fracture (Uto Karem Smash Up Remix)<br/>
                    Muovo - All My Love (Emanuel Satie Remix)
                </div>
            },
            {
                season: 1,
                orderNumber: 5,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast005',
                info: <div>
                    Der fünfte entropieCast, ist ein traumhaft facettenreicher Mix, abseits des Techno Mainstreams.<br/>
                    Entropie Resident Snoozi, liefert uns damit einen erfrischend flauschigen Start in den Sommer.<br/>
                    <br/>
                    WAS HAST DU IN LETZTER ZEIT SO GEMACHT?<br/>
                    <br/>
                    Praktischerweise bin ich auf dem Höhepunkt der COVID-19 Pandemie in eine neue Wohnung gezogen.<br/>
                    Da ich derzeit leider keine Stelle bei einer Berliner Filmcrew bekomme, konzentriere ich mich jetzt zunächst darauf, keine Bookings von Berliner Clubs zu erhalten. Der Stillstand kann (uns privilegierten Mitteleuropäer:innen) dabei helfen, neue Wege zu erkunden und alten Müll hinter sich zu lassen.<br/>
                    <br/>
                    WAS IST DIE IDEE HINTER DEM MIX?<br/>
                    <br/>
                    Der Mix dient zur Entspannung während der Naherholung. Für einen Tag auf dem Balkon oder im Park. Um die Hüften zu lockern und die Nachbarn in Aufregung zu versetzen.<br/>
                    <br/>
                    WAS HAST DU SO DEMNÄCHST GEPLANT?<br/>
                    <br/>
                    Meinen Abschlussfilm fertig schneiden. Weiterhin zum massiven Überangebot an Livestream und Podcast-Formaten beitragen. Augen und Ohren offen halten. Soziale Organisationen supporten. Sich gegenseitig bei Projekten/Aktionen unterstützen. Meditieren. Bretter an Wände schrauben und natürlich das Patriarchat zerschlagen.<br/>
                    <br/>
                    Tracklist:<br/>
                    <br/>
                    Blond:ish - It starts now<br/>
                    Romare - L.U.V.<br/>
                    John Talabot - Voices(Gerd Janson Conga Version)<br/>
                    John Talabot feat. Pional - So will be now…<br/>
                    Brioski - Bump<br/>
                    Curses - Surrender(Jennifer Cardini Remix)<br/>
                    Pional - Invisible/Amenaza<br/>
                    Doppelate - Eye Contact<br/>
                    Yuksek, Fatnotronic - Bateu(Original Mix)<br/>
                    Dauwd - Lydia<br/>
                    Baaz - Endori(Dorisburg Remix)<br/>
                    Dorisburg - Gloson<br/>
                    Kombinat 100 - Flieg kleine Taube, flieg…(Album Mix)
                </div>
            },
            {
                season: 1,
                orderNumber: 4,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast004',
                info: <div>
                    Der vierte entropieCast, kommt von einem echten Multitalent. Er tätowiert, macht wunderschöne Kalligrafien und ist außerdem für melodisch treibende Vinylsets bekannt. Ole Niedermauntel.<br/>
                    <br/>
                    WAS HAST DU IN LETZTER ZEIT SO GEMACHT?<br/>
                    <br/>
                    Viel gemalt aber auch viel abgehartzt und in den Tag hineingelebt. Mehr ging ja eigentlich auch nicht.<br/>
                    <br/>
                    WAS IST DIE IDEE HINTER DEM MIX?<br/>
                    <br/>
                    Ich bin ein großer Fan von Storytelling und das versuche ich in all meinen Sets, anhand der richtigen Trackauswahl, zum Ausdruck zu bringen. Stumpfes aneinanderreihen von Tracks ohne Sinn und Verstand, getreu dem Motto „schneller, härter, doller finde ich einfach langweilig und auf Dauer ermüdend. Allerdings scheint genau das, seit geraumer Zeit total Trend zu sein…schade eigentlich.<br/>
                    Mir ist es wichtig dass die Sets im Wohnzimmer, als auch auf dem Floor funktionieren und den Zuhörer abholen, ich hoffe das ist mir mit diesem Podcast gelungen.<br/>
                    <br/>
                    WAS HAST DU DEMNÄCHST SO GEPLANT?<br/>
                    <br/>
                    Ich plane generell wenig und lass einfach vieles auf mich zukommen. In der aktuellen Situation genau der richtige Modus.
                </div>
            },
            {
                season: 1,
                orderNumber: 3,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast003',
                info: <div>
                    Den nächsten entropieCast liefert einer unserer top-notch Residents. Wer ein deftiges Tanz-Workout sucht, wird bei ihm definitiv fündig. Wir freuen uns ihn in unseren Reihen zu haben und sind bereit für ein weiteres schweißtreibendes Home-Workout. Klackwerk<br/>
                    <br/>
                    WAS HAST DU IN LETZTER ZEIT SO GEMACHT?<br/>
                    <br/>
                    Ich widme mich in der Zeit der Kontaktsperre komplett dem Einstudieren meiner Performance für den Uckermärkischen Kleingartenwettbewerb.<br/>
                    Meine Gartenzwerge Tiffy, Klaus und Helga sind schon schick, meine Katze Penny „The Pieps“ Gonzalez nimmt noch Gesangsunterricht<br/>
                    <br/>
                    WAS IST DIE IDEE HINTER DEM MIX?<br/>
                    <br/>
                    Wie lange können die Clubs geschlossen bleiben bis unsere NachbarInnen ob der lauten Musik durchdrehen?<br/>
                    - Bei uns bis vor ca. 4 Wochen<br/>
                    <br/>
                    WAS HAST DU DEMNÄCHST SO GEPLANT?<br/>
                    <br/>
                    Grenzen einreißen, Schweine beschmeißen, Banden bilden, Body builden
                </div>
            },
            {
                season: 1,
                orderNumber: 2,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast002',
                info: <div>
                    Seit vielen Jahren in der Berliner Szene unterwegs und respektiert, engagiert er sich bei seinen Labels Lounge Squatt & Berlin Invasion, ist Radiohost bei Unika Fm Radio & Fnoob Techno Radio und neben seinen Auftritten als DJ, haut er natürlich auch noch regelmäßig eigenen frischen Content raus.<br/>
                    Wir sind sehr froh, dass wir ihn für den zweiten Teil unserer neuen Podcast-Reihe entropieCast gewinnen konnten, freuen uns aber natürlich auch sehr darauf ihn in Klingemühle auf unserer Festivalbühne erleben zu dürfen. Groove Daniel<br/>
                    <br/>
                    For many years he has been on the Berlin scene and is highly respected. He is pushing his own labels Lounge Squatt & Berlin Invasion, he is radio host for Unika Fm Radio & Fnoob Techno Radio and besides his gigs as a DJ he also regularly releases his own fresh content.<br/>
                    We are very happy that we could win him for the second part of our new podcast series entropieCast, but we are also really looking forward to his performance on our festival stage in Klingemühle. Groove Daniel<br/>
                    <br/>
                    WHAT HAVE YOU BEEN UP TO RECENTLY?<br/>
                    <br/>
                    2020 is the year of celebration of my 2 labels: Lounge Squatt and Berlin Invasion, to celebrate these years of activity 2 albums will be released: one on vinyl on Berlin Invasion with my track and a guest appearance by D.A.V.E The drummer, Patrick DSP and Sonico also various digital artists on lns that count for us.<br/>
                    In a nutshell artists that have worked for the label in the last year and some new artists, we also have a release with a B side project called Hybrid(De) on the french label Music from Exo Planet, in addition to this there was a commemorative tour and several gigs, which unfortunately have been postponed due Covid-19 which is affecting the whole world, so I'm dedicating this quarantine in the studio to preparing new tracks, and recording new mixes, continuing my radio show, building a new live act and hoping for a better future!<br/>
                    <br/>
                    CAN YOU TELL US ABOUT THE IDEA BEHIND THE MIX?<br/>
                    <br/>
                    The idea of the mix is to bring the audience an hour of my musical background, which for about fifteen years has been between techno and bass music based with different shades, the idea is to give a "festival" touch in the mix and all podcasts try to create a timeless mix by alternating productions from my labels, my tracks, bootlegs, vinyl rarities etc etc.<br/>
                    The mix was recorded on vinyl and digital with 2 cdj's, an analog reverb and delay and external effects.<br/>
                    I hope you Enjoy<br/>
                    <br/>
                    WHAT ARE YOU UP TO NEXT?<br/>
                    <br/>
                    My future projects include: a new live act that will visit psychedelic sound, continue the productions on my labels, keep gigging, the creation of a festival for 2021 with the idea of bringing music more into the social domain also promoting sustainable living. There is a lot of work to do, but the motivations are not lacking, see you at the Entropie Festival 2020.<br/>
                    <br/>
                    Tracklist:<br/>
                    <br/>
                    Deepchord - Roca 9 (Original Mix)<br/>
                    The future sound of London - Image of the Past (Original Mix)<br/>
                    Claus Schoning . Wizard (Original Mix)<br/>
                    Calibre - Thunder Fog (Original Mix)<br/>
                    Astronomy Domine - Incosapevolmente (Original Mix)<br/>
                    Fabrizio Lapiana & Nothing Is Real - Desolate (Original Mix)<br/>
                    Groove Daniel - Kill your Tv (Original Mix)<br/>
                    Sonico - Dance or Die (Original Mix)<br/>
                    Acidupdub - War of the worlds (Original Mix)<br/>
                    Q Cumb - Dreamers 232342 (Original Mix)<br/>
                    Matasism - Acid Energy (Original Mix)<br/>
                    Christian Piers - 2020202 (Original Mix)<br/>
                    Society Of Silence - L’ortophoniste (Original Mix)<br/>
                    Krampf - LSD XTC (DJ Gigola & Kev Koko Paranoia Mix)<br/>
                    Patrick Dre- Pose (Stigmatique rmx)<br/>
                    Moby - Porcelain (Alan Fitzpatrick's Late Night Dub)
                </div>
            },
            {
                season: 1,
                orderNumber: 1,
                soundcloud: 'https://soundcloud.com/entropiefestival/entropiecast001',
                info: <div>
                    Letztes Jahr hat sein treibendes Set den ganzen Ankerplatz zum kochen gebracht und natürlich ist er auch 2020 wieder dabei.<br/>
                    Bis es aber soweit ist, startet er jetzt erstmal mit uns die neue Podcastreihe "entropieCast". Juelito.<br/>
                    <br/>
                    WAS HAST DU IN LETZTER ZEIT SO GEMACHT?<br/>
                    <br/>
                    Meine Tochter gehütet. Tag wie Nacht. So langsam kribbelts aber in meinen Fingern .. zugegebener Maßen!<br/>
                    <br/>
                    WAS IST DIE IDEE HINTER DEM MIX?<br/>
                    <br/>
                    Eine Zusammenfassung meiner liebsten Sessions aus vergangener Zeit: ein Track für jedes Set mit besonderen Erinnerungen.<br/>
                    Mit Spannung versehen, in der Harmonie der Höhepunkt, durch und durch treibend und das Ende offen. Dazu das Knistern der Scheiben.. willkommen in meiner Welt.<br/>
                    <br/>
                    WAS HAST DU DEMNÄCHST SO GEPLANT?<br/>
                    <br/>
                    Besondere Orte meiner Heimat bespielen, meiner Tochter die Welt zeigen und von einer geilen entropie träumen.<br/>
                    Unabhänig davon hoffe ich dass dieser Covid-Spuk bald ein Ende findet, denn der Mensch muss tanzen, braucht Musik, Kultur und Freiraum.<br/>
                    <br/>
                    Tracklist:<br/>
                    <br/>
                    Pallida - Mushi Mushi (Eco Friendly)<br/>
                    Viers - B1 Blow<br/>
                    Ivanshee - Serpantine<br/>
                    Souk Button - A1 Cadance<br/>
                    Paul Nolan - Form Constants<br/>
                    Andre Crom - Resonance (BEC's Last Track Remix)<br/>
                    Lio Q - Ad Hominem (Christian Monique Rmx)<br/>
                    ANNA - B1 Leave House<br/>
                    Luis M - Extremly Low Frequency<br/>
                    Clint Steward - B1 Nias<br/>
                    Linus & Amy - Our Secret Tunnel (Ashwin Khosa Remix)<br/>
                    Alan Fitzpatrick - Lexicon
                </div>
            }
        ]
    }
}

export default PodcastContent;