import React from 'react';

class RightImage extends React.Component {
    render() {
        return (
            <img className="RightImage" src={this.props.source} alt=""/>
        )
    }
}

export default RightImage;