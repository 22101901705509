import axios from 'axios';
import Config from '../config/Config';

class Newsletter {
    static createEntry(email) {
        return axios.post(`${Config.api()}newsletter/create`, {
            email
        });
    }

    static confirmEntry(token) {
        return axios.put(`${Config.api()}newsletter/confirm`, {
            token
        });
    }
}

export default Newsletter;